import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoggerService } from 'src/app/api/logger.service';

@Injectable({
  providedIn: 'root',
})
export class PhoneKeyboardService {
  private keyboardIsShowing = false;
  private onKeyboardStateChangeSource = new Subject<boolean>();
  /** Praneša apie tai, jog pasikeitė telefono klaviatūros būsena. */
  /** @param state __boolean__: _true_ - klaviatūra atidaryta, _false_ - klaviatūra uždaryta */
  public onKeyboardStateChange = this.onKeyboardStateChangeSource.asObservable();

  constructor(l: LoggerService) {
    l.log('+', 'KB');
    window.keyboardJsi = {
      onKeyboardEvent: (value) => {
        this.setKeyboardState(value);
      },
    };
  }

  /**
   * Nurodo ar šiuo metu telefone atidaryta klaviatūra.
   */
  public isKeyboardVisible(): boolean {
    return this.keyboardIsShowing;
  }

  private setKeyboardState(visible: boolean) {
    this.keyboardIsShowing = visible;
    this.onKeyboardStateChangeSource.next(visible);
  }
}
