<div id='ec-main'>
    <div id='ec-desc'>{{trans('systems.labels.showHideEventsDesc')}}</div>
    <div id='ec-container'>
        <div class="ec-group" *ngFor="let iGroup of groupedReactions">
            <div class="ec-background group-background"></div>
            <div class="ec-group-name">{{trans('reactionNames.reaction_groups.' + iGroup.group)}}</div>
            <div class="ec-reaction-list">
                <div class="ec-reaction" *ngFor="let iReaction of iGroup.reactions">
                    <app-checkbox [isRound]='false' [isSecondaryColor]='true' class="ec-check" [checkValue]="isVisible(iReaction.id)" (valueChanged)="setVisible(iReaction.id, $event)"></app-checkbox>
                    <div class="ec-name" (click)="setVisible(iReaction.id)">{{trans('reactionNames.reactions.' + iReaction.name)}}</div>
                    <div class="ec-icon" (click)="setVisible(iReaction.id)" [inlineSVG]="reactions.getReactionIcon(iReaction.id)"></div>
                </div>
            </div>
        </div>
    </div>
</div>