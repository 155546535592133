import { Injectable } from '@angular/core';
import { ApiRequestService } from './api-request.service';
import { LoggerService } from '../api/logger.service';

@Injectable({
  providedIn: 'root',
})
export class TimezoneService {
  /**
   * Statusas reikalingas žinoti, kad šiuo metu kraunamos zonos ir antrą kartą to daryti nereikia.
   */
  private loadingTimeZones = false;
  public availableTimeZones = [];
  public userTimeZone = '';
  private tag = 'TimeZones';

  constructor(private rs: ApiRequestService, private l: LoggerService) {
    this.l.log('+', this.tag);
    const timeZonesStr = localStorage.getItem('time_zone_list');
    if (timeZonesStr === null) {
      return;
    }

    this.availableTimeZones = JSON.parse(timeZonesStr);
    this.l.log('Viskas ok, jau turim issisaugoje laiko juostas.', this.tag);
    const userTimezone = localStorage.getItem('time_zone_user');
    if (userTimezone !== null) {
      this.userTimeZone = userTimezone;
    }
  }

  public loadTimeZones() {
    if (this.availableTimeZones.length > 0) {
      return;
    } // Nekartojam jeigu jau turim.
    if (this.loadingTimeZones) {
      return;
    } // Vis dar kraunamos zonos.
    this.loadingTimeZones = true;

    const that = this;
    this.rs.get('/timezones', true).subscribe(
      (result) => {
        that.availableTimeZones = result.timeZones;
        that.userTimeZone = result.userTimeZone;
        localStorage.setItem('time_zone_list', JSON.stringify(that.availableTimeZones));
        localStorage.setItem('time_zone_user', that.userTimeZone);
        that.l.log('Zones received. ', that.tag);
        that.loadingTimeZones = false;
      },
      (error) => {
        that.loadingTimeZones = false;
        that.userTimeZone = 'UTC';
      }
    );
  }
}
