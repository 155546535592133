import http from '../http';
import { BasicSuccessResponse, ErrorResponse, ExErrorResponse, SensorDataWithValues, SensorIconData } from './common';

type GetSensorValuesRequest<SystemId extends number> = { systemId: SystemId };

type GetSensorValuesResponse<SystemId extends number> =
  | ErrorResponse
  | (BasicSuccessResponse & { sensors: SensorDataWithValues[] });

export const getSensorValues = <SystemId extends number>(req: GetSensorValuesRequest<SystemId>) =>
  http.post<GetSensorValuesResponse<SystemId>, GetSensorValuesRequest<SystemId>>('/v3/api/get-sensor-values', req, { silentOnError: true });

type EditSensorRequest = {
  systemId: number;
  sensor: {
    name: string;
    type: number;
    queue_no: number;
    notificationAboutHigh?: boolean;
    notificationAboutLow?: boolean;
    highValue?: number;
    lowValue?: number;
  };
};
type EditSensorResponse = ErrorResponse | ExErrorResponse | BasicSuccessResponse;

export const editSensor = (req: EditSensorRequest) => http.post<EditSensorResponse, EditSensorRequest>('/v3/api/edit/sensor', req);

type SensorIconsResponse = BasicSuccessResponse & {
  icons: SensorIconData[];
  version: string;
};
export const getSensorIcons = () => http.post<SensorIconsResponse>('/v3/api/get-sensor-types', undefined, { auth: 'ifHas' });
