<div id='bpz-slideout' (click)='onCancelClick()'>
    <div id='bpz-grid' class="grid" [@showWindow]='isVisible' (click)='$event.stopPropagation()'>
        <div id='bpz-background' class="group-background"></div>
        <div id='bpz-title'>{{trans('systems.titles.bypassZones')}}</div>
        <app-checkbox id='bpz-check-all' [isBig]='true' [isRound]='false' [isSecondaryColor]='true' (valueChanged)='onAllClicked($event)' [checkValue]='allSelected'>{{trans('systems.labels.selectAll')}}</app-checkbox>
        <div id='bpz-check-list'>
            <app-checkbox *ngFor='let iZone of dataSource' [isBig]='true' [isRound]='false' [isSecondaryColor]='true' (valueChanged)='onZoneSelected(iZone.number, $event)' [checkValue]='iZone.isChecked'>{{iZone.title}}</app-checkbox>
        </div>
        <div id='bpz-cancel' (click)='onCancelClick()'>{{trans('general.cancel')}}</div>
        <div id='bpz-confirm' (click)='onConfirmClick()'>{{trans('users.buttons.confirm')}}</div>
    </div>
</div>