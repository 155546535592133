import { ComponentRef, EmbeddedViewRef, Injectable, OnDestroy } from '@angular/core';
import { SystemCameraData } from 'src/api/v3/common';
import { OpenVideoHandlerCameraObject, TCamera } from 'src/app/models/camera';
import { AuthService } from '../auth.service';
import { CameraViewComponent } from 'src/app/popups/camera-view/camera-view.component';
import { RefreshService } from 'src/app/services/refresh.service';
import { SystemsService } from 'src/app/services/systems.service';
import { PlatformService } from '../platform.service';
import { LoggerService } from '../logger.service';

@Injectable({
  providedIn: 'root',
})
export class CameraService implements OnDestroy {
  public readonly cameras = new Map<number, TCamera>();
  public readonly systemCameras = new Map<number, Set<number>>();
  private instance: CameraViewComponent;
  private readonly tag = 'CameraService';

  private cleanupSubscribtion = this.auth.onAccountOrRegionChnage.subscribe(() => {
    this.cameras.clear();
    this.systemCameras.clear();
  });
  constructor(
    private auth: AuthService,
    private refresher: RefreshService,
    private systems: SystemsService,
    private platform: PlatformService,
    private l: LoggerService,
  ) {}

  ngOnDestroy(): void {
    this.cleanupSubscribtion.unsubscribe();
  }

  ingestCamera(camera: SystemCameraData): TCamera {
    const { system_id: systemId, full_url: fullUrl, assigned_pgms, assigned_zones, ...rest } = camera;
    const processedCamera: TCamera = {
      fullUrl,
      assignedPgms: assigned_pgms ?? [],
      assignedZones: assigned_zones ?? [],
      ...rest,
    };

    this.cameras.set(camera.id, processedCamera);
    if (!this.systemCameras.has(systemId)) {
      this.systemCameras.set(systemId, new Set());
    }
    this.systemCameras.get(systemId)?.add(camera.id);
    return processedCamera;
  }

  public ingestCameraData(camera: TCamera, systemId: number) {
    this.cameras.set(camera.id, camera);
    if ( !this.systemCameras.has(systemId) ) {
      this.systemCameras.set(systemId, new Set<number>());
    }
    if ( !this.systemCameras.get(systemId).has(camera.id) ) {
      this.systemCameras.get(systemId).add(camera.id);
    }
  }

  public openCameraView(cameras: TCamera[], cameraViewComponentRef: ComponentRef<CameraViewComponent>) {
    const camerasWithProtocolHTTPorHTTPS = cameras.filter((camera) => camera.protocol === 'https' || camera.protocol === 'http');
    if (camerasWithProtocolHTTPorHTTPS.length === 0) {
      return;
    }
    let openInExternalView = false;
    if (cameras && cameras.length > 0) {
      const httpOrHttpsCameras = cameras.filter((c) => c.fullUrl.startsWith('http'));
      if (httpOrHttpsCameras.length > 0) {
        const cameraUrl = httpOrHttpsCameras[0].fullUrl;
        if (cameraUrl) {
          openInExternalView = true;
          window.open(cameraUrl, '_blank');
        }
      }
    }
    if(openInExternalView) {
      return;
    }
    this.l.log('Atidarom kameros vaizda desktope', this.tag, cameras);
    const refresherIsEnabled = this.refresher.refresherIsEnabled;
    this.refresher.disableRefresher();
    this.instance = cameraViewComponentRef.instance;
    this.instance.cameras = cameras;
    this.instance.cameraViewComponentRef = cameraViewComponentRef;
    const closeSubscriber = this.instance.cameraViewClose.subscribe(() => {
      if (refresherIsEnabled) {
        this.refresher.enableRefresher();
      }
      closeSubscriber.unsubscribe();
    });
    const domElem = (cameraViewComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
  }

  public cameraViewIsOpen(): boolean {
    return !!this.instance;
  }

  public closeCameraView() {
    if (!this.cameraViewIsOpen()) {
      return;
    }
    this.instance.clickCancel();
    this.instance = null;
  }

  public getCameraObjectForVideoHandler(camera: TCamera): OpenVideoHandlerCameraObject {
    const assignedPgmIds: number[] = [];
    const assignedPgmNames: string[] = [];
    for (const iPgm of camera.assignedPgms) {
      const pgm = this.systems.activeSystem.pgms.find((p) => p.queue_no === iPgm);
      if (pgm !== undefined) {
        assignedPgmIds.push(pgm.id);
        assignedPgmNames.push(pgm.name);
      }
    }
    const dataToReturn: OpenVideoHandlerCameraObject = {
      url: camera.fullUrl,
      cameraName: camera.name,
      pgmIds: assignedPgmIds,
      pgmNames: assignedPgmNames,
      systemId: this.systems.activeSystem.id,
    };

    return dataToReturn;
  }

  public openMobileCameraView(cameras: OpenVideoHandlerCameraObject[]) {
    if(cameras.length === 0) {
      return;
    }
    if (this.platform.isAndroid()) {
      this.l.log('Atidarom kameros vaizda androide', this.tag, cameras);
      try {
        if(cameras.length > 1) {
          this.platform.androidHandler().openVideos(cameras.map((camera) => ({ url: camera.url, cameraName: camera.cameraName, pgmIds: camera.pgmIds, pgmNames: camera.pgmNames, systemId: camera.systemId })));
        } else {
          if(cameras[0].url.startsWith('http')) {
            this.platform.androidHandler().openExternalUrl(cameras[0].url);
          } else {
            this.platform.androidHandler().openVideo(cameras[0].url, cameras[0].cameraName, cameras[0].pgmIds, cameras[0].pgmNames, cameras[0].systemId);
          }
        }
      } catch (err) {
        if(cameras.length > 1) {
          this.platform.androidHandler().openVideos(cameras.map((camera) => ({ url: camera.url, cameraName: camera.cameraName })));
        } else {
          this.platform.androidHandler().openVideo(cameras[0].url, cameras[0].cameraName);
        }
      }
    } else if (this.platform.isApple()) {
      this.l.log('Atidarom kameros vaizda iOSe', this.tag, cameras);
      if(cameras.length > 1) {
        this.platform.appleHandler().openVideos.postMessage(cameras.map((camera) => ({ url: camera.url, name: camera.cameraName, pgmIds: camera.pgmIds, pgmNames: camera.pgmNames, system: camera.systemId })));
      } else {
        if(cameras[0].url.startsWith('http')) {
          this.platform.appleHandler().openExternalUrl.postMessage(cameras[0].url);
        } else {
          this.platform.appleHandler().openVideo.postMessage({
            url: cameras[0].url,
            name: cameras[0].cameraName,
            pgmIds: cameras[0].pgmIds,
            pgmNames: cameras[0].pgmNames,
            system: cameras[0].systemId,
          });
        }
      }
    }
  }

}
