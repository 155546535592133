import { AfterViewInit, ApplicationRef, Component, ComponentRef, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { LanguageService } from 'src/app/services/language.service';
import { BasePopupComponent } from '../base-popup/base-popup.component';
import { ConfigsWithCancelOrCloseButton, ConfigsWithSecondButton, DefaultSetupConfigs, LinkStyleConfigs, MessageBoxButtons, MessageBoxCheckbox, MessageBoxIcon } from './messagebox-config';
import { CommonModule } from '@angular/common';
import { CheckboxComponent } from 'src/app/general/checkbox/checkbox.component';
import { InlineSVGModule } from 'ng-inline-svg-w';

@Component({
  selector: 'app-messagebox',
  templateUrl: './messagebox.component.html',
  styleUrls: ['./messagebox.component.scss'],
  standalone: true,
  imports: [CommonModule, CheckboxComponent, InlineSVGModule, BasePopupComponent],
})
export class MessageboxComponent implements OnInit, AfterViewInit, OnDestroy {
  public MessageBoxButtons = MessageBoxButtons;
  public MessageBoxIcon = MessageBoxIcon;
  public LinkStyleConfigs = LinkStyleConfigs;
  public DefaultSetupConfigs = DefaultSetupConfigs;
  public ConfigsWithCancelOrCloseButton = ConfigsWithCancelOrCloseButton;
  public ConfigsWithSecondButton = ConfigsWithSecondButton;
  public buttonClassSelector: string;
  public youtubeVideoUrl: SafeResourceUrl;
  public cameraSafeUrl: SafeResourceUrl;
  public scrolledToBottom: boolean;
  public activeCameraIndex = 0;
  private systemChangedListener = null;

  @Input() buttons: MessageBoxButtons = MessageBoxButtons.Ok;
  @Input() iconType: MessageBoxIcon | undefined;
  @Input() button1Text: string | undefined;
  @Input() button2Text: string | undefined;
  @Input() showCloseButton = true;
  @Input() canCloseByOverlay = false;
  @Input() mustScrollToBottom: boolean | undefined;
  @Input() messageContent: SafeHtml | string | undefined;
  @Input() headerText: string | undefined;
  @Input() youtubeVideoId: string | undefined;
  @Input() messageboxComponentRef: ComponentRef<MessageboxComponent>;
  @Input() alignCenter = false;
  @Input() checkboxes: MessageBoxCheckbox[] = [];
  @Input() subtextTopRight: string | undefined;
  @Input() subtextTopLeft: string | undefined;
  @Input() subtextBottomRight: string | undefined;
  @Input() subtextBottomLeft: string | undefined;
  // Neleidžia tęsti Button1Click jeigu funkcija gražina 'false'
  @Input() beforeSumbitButton1: () => boolean;
  // Neleidžia tęsti Button2Click jeigu funkcija gražina 'false'
  @Input() beforeSumbitButton2: () => boolean;
  @Output() okClicked: EventEmitter<MessageBoxCheckbox[]> = new EventEmitter();
  @Output() yesClicked: EventEmitter<MessageBoxCheckbox[]> = new EventEmitter();
  @Output() noClicked: EventEmitter<any> = new EventEmitter();
  @Output() cancelClicked: EventEmitter<any> = new EventEmitter();
  @Output() button1Clicked: EventEmitter<MessageBoxCheckbox[]> = new EventEmitter();
  @Output() button2Clicked: EventEmitter<MessageBoxCheckbox[]> = new EventEmitter();
  @Output() messageboxClose: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('msgbText', { static: true }) msgbText!: ElementRef;

  constructor(
    public l: LanguageService,
    private appRef: ApplicationRef,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.setButtonClasses();
    this.youtubeVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.youtubeVideoId);
  }

  ngAfterViewInit(): void {
    if(this.mustScrollToBottom) {
      this.addScrollListener();
      this.onScroll();
    }
  }

  ngOnDestroy() {
    if (this.systemChangedListener !== null) {
      this.systemChangedListener.unsubscribe();
      this.systemChangedListener = null;
    }
  }

  private addScrollListener(): void {
    if (this.msgbText && this.msgbText.nativeElement) {
      this.msgbText.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
    }
  }

  private onScroll(): void {
    const element = this.msgbText.nativeElement as HTMLElement;
    this.scrolledToBottom = element.scrollHeight - element.scrollTop - 1 <= element.clientHeight;
    setTimeout(() => {
      this.setButtonClasses();
    });
  }

  public clickOk() {
    if(this.mustScrollToBottom && !this.scrolledToBottom) { return }
    this.okClicked.emit(this.checkboxes);
    this.removeMessageboxFromView();
  }

  public clickYes() {
    if(this.mustScrollToBottom && !this.scrolledToBottom) { return }
    this.yesClicked.emit(this.checkboxes);
    this.removeMessageboxFromView();
  }

  public clickNo() {
    if(this.mustScrollToBottom && !this.scrolledToBottom) { return }
    this.noClicked.emit();
    this.removeMessageboxFromView();
  }

  public clickCancel() {
    this.cancelClicked.emit();
    this.removeMessageboxFromView();
  }

  public async clickButton1() {
    if(this.mustScrollToBottom && !this.scrolledToBottom) { return }
    if ( this.beforeSumbitButton1 && ! await this.beforeSumbitButton1() ) {
      return;
    }
    this.button1Clicked.emit(this.checkboxes);
    this.removeMessageboxFromView();
  }

  public async clickButton2() {
    if(this.mustScrollToBottom && !this.scrolledToBottom) { return }
    if ( this.beforeSumbitButton2 && ! await this.beforeSumbitButton2() ) {
      return;
    }
    this.button2Clicked.emit(this.checkboxes);
    this.removeMessageboxFromView();
  }

  private removeMessageboxFromView(): void {
    this.messageboxClose.emit(true);
    if(this.messageboxComponentRef) {
      this.appRef.detachView(this.messageboxComponentRef.hostView);
      this.messageboxComponentRef.destroy();
    }
  }

  private setButtonClasses() {
    this.buttonClassSelector = this.LinkStyleConfigs.includes(this.buttons) ? (this.mustScrollToBottom && !this.scrolledToBottom ? 'msgb_button_link msgb_button_link_disabled' : 'msgb_button_link') : (this.mustScrollToBottom && !this.scrolledToBottom ? 'msgb_button msgb_button_disabled' : 'msgb_button');
  }

  @HostListener('document:keydown.escape')
  onEscapeClick() {
    if ( this.ConfigsWithCancelOrCloseButton.includes(this.buttons) || this.canCloseByOverlay || this.showCloseButton) {
      this.clickCancel();
    }
  }
}
