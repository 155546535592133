<div id='home-cameras-section-header'>{{trans('systems.titles.systemCameras')}}</div>
<div id='home-cameras-section-container'>
    <app-long-press-menu *ngFor='let iCamera of visibleCameras' [class]="getCameraSizeClass(iCamera)" [topAdjustment]="10" [id]="'cam' + iCamera.id">
        <div slot="content" (click)='cameraClick(iCamera)' class="camera-button-grid">
            <div class="camera-button-icon {{ flatButton ? 'camera-button-icon_flat' : '' }}"><div  [inlineSVG]="'assets/images/camera2.svg'"></div></div>
            <div class="camera-button-text" *ngIf="nameTextColor === ''">{{iCamera.name}}</div>
            <div class="camera-button-text" *ngIf="nameTextColor !== ''" [style.color]="nameTextColor">{{iCamera.name}}</div>
        </div>
        <div slot="menu">
            <div (click)='editCamera(iCamera)' *ngIf="this.systems.activeSystemHelper.can.edit.cameras()">
                <div><svg viewBox="0 0 19.064 19.064"><path d="M4.5,19.589v3.971H8.471L20.184,11.848,16.212,7.877ZM23.255,8.777a1.055,1.055,0,0,0,0-1.493L20.777,4.806a1.055,1.055,0,0,0-1.493,0L17.346,6.744l3.971,3.971Z" transform="translate(-4.5 -4.496)"/></svg></div>
                <span>{{lang.get('systems.buttons.edit')}}</span>
            </div>
            <div (click)='editCameraSize(iCamera)'>
                <div><svg viewBox="0 0 23 23"><g transform="translate(-48.233 -96)"><g transform="translate(48.233 96)" fill="none" stroke="currentcolor" stroke-width="1" stroke-dasharray="2"><rect width="23" height="23" rx="7" stroke="none"/><rect x="0.5" y="0.5" width="22" height="22" rx="6.5" fill="none"/></g><rect width="13" height="7" rx="3" transform="translate(48.531 112)"/></g></svg></div>
                <span>{{lang.get('systems.buttons.resize')}}</span>
            </div>
        </div>
    </app-long-press-menu>
</div>