import { SafeUrl } from '@angular/platform-browser';
import { HomeConfiguration, HomeConfigurationRaw } from 'src/app/models/home-configuration';
import { IPCom } from 'src/app/models/ipcom';
import { isBlob } from '../http';
import { Company as CompanyType } from './company';
import { Data, Route as NgRoute } from '@angular/router';
import { PanicSettings } from './settings';
import { SystemPgmData } from 'src/app/models/pgm';
import { SystemAreaData } from './system.area';
import { Tag } from './tag';
import { Permission, PermissionList, PermissionRule } from './permission';
import { OwnerPrivacySetting } from './system.user';
import { SosType, SystemStatus, SystemTrouble } from './system';

export type ErrorResponse = { success: false; error: string };
export type ExErrorResponse = ErrorResponse & { error_code: number };
export type BasicSuccessResponse = { success: true };
export type WithLang = { lang?: string };
export type WithWarning = { warning: string };

type FlatFormData = [string, string | Blob][];
type BasicFormDataValues = string | number | bigint | boolean | Blob;
type RecursiveFormDataSolverValue = BasicFormDataValues | { [key: string]: RecursiveFormDataSolverValue } | RecursiveFormDataSolverValue[];

const prefixError = (): never => {
  throw new Error('Can\'t have nameless top values');
};

const recursiveFormDataSolver = (value: RecursiveFormDataSolverValue, prefix?: string): FlatFormData => {
  const name = () => prefix ?? prefixError();
  switch (typeof value) {
    case 'string':
      return [[name(), value]];
    case 'bigint':
    case 'number':
    case 'boolean':
      return [[name(), value.toString()]];
    case 'object':
      if (Array.isArray(value)) {
        return value.flatMap((v, i) => recursiveFormDataSolver(v, `${prefix ?? ''}[{${i}}]`));
      }
      if (isBlob(value)) {
        return [[name(), value]];
      }
      if ( !value ) {
        return [[name(), '']]; // null|undefined => ''
      }
      return Object.entries(value).flatMap(([n, v]) => recursiveFormDataSolver(v, `${prefix ? prefix + '.' : ''}${n}`));
    case 'undefined':
      return [[name(), '']]; // null|undefined => ''
    default:
      return value;
  }
};

export const objectToFormData = <TValues extends RecursiveFormDataSolverValue, TObject extends { [key: string]: TValues }>(data: TObject): FormData => {
  const fd = new FormData();
  recursiveFormDataSolver(data, '').forEach(([n, v]) => fd.append(n, v));
  return fd;
};

// Convert js object to query params compatible with php arrays
export const objectToParams = <TValues extends RecursiveFormDataSolverValue, TObject extends { [key: string]: TValues }>(data: TObject): URLSearchParams => {
  const params = new URLSearchParams();
  if (data) {
    recursiveFormDataSolver(data, '')
      .filter(([, v]) => !isBlob(v))
      .forEach(([n, v]) => params.append(n, v as string));
  }
  return params;
};

//@TODO UserData ir TUser ateity reikia suderinti. Ši struktūra naudojama duomenims iš DB, o TUser struktūroje yra atlikta keletas duomenų modifikacijų.
export type CurrentUserData = {
  id: number;
  name: string;
  email: string;
  socket_token: string;
  socket_port: number;
  token: string;
  phone: string;
  date_format: number;
  time_format: string;
  language: string;
  is_social_account: boolean;
  isPasswordSet: boolean;
  homeConfigurations: HomeConfiguration[];
  active: number;
  country: string | null;
  appVersion: {
    major: number;
    minor: number;
    build: number;
    date: number;
  };
  ownedCompanies: CompanyType[];
  belongsToCompany: CompanyType | null;
  logo: string | null;
  settings: {
    textual: {
      name: string;
      field: string;
      value: string;
    }[];
    togglable: {
      name: string;
      field: string;
      value: boolean;
    }[];
  };
  permissions: PermissionRule;
  lastSeenReactions?: {
    reaction_id: number;
    unseen_events: number;
    last_event_id: number;
  }[];
  reactions?: ReactionData[];
  historySystems?: HistorySystem[];
  panic_settings?: PanicSettings[];
  limits: {
    default_system_count: boolean;
    system_count: number;
    default_camera_count: boolean;
    camera_count: number;
  };
  ipcoms?: IPCom[];
  regions?: Region[];
  company_id: number;
  logoDataForStorage?: string;
  access_permission_id: number;
  permission_rules: PermissionRule[];
  companies?: CompanyType[];
  user_tags: UserTag[];
  pending_systems?: number;
};

export type UserTag = { id: number; user_id: number; tag_id: number };

export type CurrentUserDataRaw = Omit<CurrentUserData, 'homeConfigurations'> & {
  homeConfigurations: HomeConfigurationRaw[];
  page_limit: {
    permissions: number;
    companies: number;
    events: number;
    systems: number;
    users: number;
    tags: number;
  };
};

export type ReactionBasicData = {
  id: number;
  name: string;
  iconPath: string;
  iconPathPng: string;
  companyId: number;
  group: string;
  defaultName: boolean;
};

export type ReactionData = {
  id: number;
  name: string;
  active: boolean;
  company_id: number;
  custom_sound: number;
  default_name: boolean;
  group_id: number;
  image: string;
  image_png: string;
  monas_id: number;
  redirect_to: number;
};

export type ReactionGroupData = {
  id: number;
  company_id: number;
  name: string;
  icon: string;
  default_name: boolean;
};

export type SystemEventData = {
  id: number;
  title: string;
  subTitle: string;
  time: number;
  area: number;
  reaction: number;
  area_event: boolean;
  event_id: number;
  user_id: number;
  user_name: string;
  tags: Tag[];
  longitude: string | null;
  latitude: string | null;
};

type SystemEventSettingsData = {
  eventTypes?: [{ name: 'All types'; company: -1 }];
  events: SystemEventData[];
  dailyEvents?: [];
  filters?: {
    date_from: null;
    date_to: null;
    area: null;
    system: null;
    type: null;
    company: -1;
    returnEventCount: true;
    forApi: true;
    offsetEvents: 0;
    offsetCount: 0;
    userName: '';
    reactions: null;
  };
};

type SystemThemeData = {
  background_start: string;
  background_end: string;
  full_background: string;
};

type SystemNotificationData = {
  success: true;
  sounds_on: boolean;
  notifications: {
    id: number;
    monas_id: number;
    name: string;
    on: boolean;
    alert_sound_on: boolean;
  }[];
  global_value: boolean;
};

export type SystemSensorData = {
  id: number;
  name: string;
  system_id: string;
  queue_no: number;
  enabled: number; // 0 ir 1?
  type: number;
  alarm_high: boolean;
  alarm_low: boolean;
  high_value: number;
  low_value: number;
};

export type SensorDataWithValues = {
  id: number;
  name: string;
  system_id: number;
  queue_no: number;
  enabled: 1 | 0;
  type: number;
  alarm_high: boolean;
  alarm_low: boolean;
  high_value: number;
  low_value: number;
  status: number; // temperatūra
  warn: boolean;
};

export type SensorIconData = {
  id: number;
  icon: string;
  name: string;
  unit: string;
  name_keyword?: string;
  unit_keyword?: string;
};

export type SystemZoneData = {
  id: number;
  name: string;
  area_id: number;
  queue_no: number;
  system_id: number;
  visible: boolean;
  native: boolean;
};

export type SystemCameraData = {
  id: number;
  name: string;
  protocol: 'rtsp' | 'https' | 'http';
  full_url: string;
  host: string;
  port: string;
  user: string;
  pass: string;
  path: string;
  system_id: number;
  assigned_pgms: number[];
  assigned_zones: number[];
};

type SystemOwnerData = {
  id: number;
  name: string;
  email: string;
  phone_number: null; // String?
  pivot: { system_id: number; user_id: number }; // Laravel artifaktas
};

export type SystemThermostatData = {
  id: number;
  name: string;
  queue_no: number;
  system_id: number;
  temperatures: string; // JSON number[]
  assigned_sensors: string; // JSON number[]
  assigned_output: number;
  active_sensor: number;
  action: number;
};

export type SystemDeviceUserData = {
  id: number;
  name: string;
  email: string;
  phone: string;
  address: string;
  key: string;
  queue_no: number;
  system_code_no?: number;
  area_id: number;
  keyboard_code?: string | '•';
  pin?: string | '•';
  protegus_user_id: number;
  system_id: number;
  areas: string | undefined; // GV atveju undefined
  can_see_events: boolean;
  canEditUsers: boolean;
  enable_data?: number;
  pgms: number | null;
  present?: boolean;
  schedule_no?: number;
  user_id?: number;
  attributes_in_panel: number;
};

export type ProtegusUserData = {
  id: number;
  name: string;
  email: string;
  active: 0 | 1;
  created_at: string;
  phone_number: string | null;
  company: string | null;
  country: string | null;
  dashboard_order: string;
  privacy_policy_accepted: boolean;
  soc_account: boolean;
  social_id: string;
  soc_type: string;
  company_id: number;
  access_permission_id: number;
  phone: string | null;
  device_setup_templates: { id: number; name: string }[];
  systems?: { id: number; name: string }[];
  user_tags?: UserTag[];
  logo: string | null;
};

export type UserSystemData = {
  id: number; // `users`.`id`
  master: boolean;
  system_permissions_id: number;
  personal_permissions: PermissionList | null;
};

export type SystemProtegusUserData = ProtegusUserData & UserSystemData;

export type SystemData = {
  id: number;
  name: string;
  hwType: string;
  supported_commands: string;
  address: string;
  amIMaster: boolean;
  online: boolean;
  canEditUsers: boolean;
  imei: string;
  mpass: string;
  timeZone: string;
  pgms: SystemPgmData[];
  events: SystemEventSettingsData;
  direct: number;
  areas: SystemAreaData[];
  noSleepStay: boolean;
  hasRealSensors: boolean;
  centralPanel: number;
  coordinates: string;
  theme: SystemThemeData;
  notifications: SystemNotificationData | null;
  sensors: SystemSensorData[];
  signalLevel: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9; // 10?
  zones: SystemZoneData[];
  canBypassZone: boolean;
  canUnbypassZone: boolean;
  protegus_users: SystemProtegusUserData[];
  device_users: SystemDeviceUserData[];
  maxDeviceUsers: number;
  eventConfiguration: string;
  cameras: SystemCameraData[];
  thermostats: SystemThermostatData[];
  installer_id: 0 | number;
  installerEmail: string;
  installerName: string;
  company_id: 0 | number;
  logo: string | null;
  supportsFireReset: boolean;
  amIWorking: boolean; // False?
  device_id: number; // 59 per tris sistemas?
  privacyOfOwners: OwnerPrivacySetting[];
  owners?: SystemOwnerData[];
  created_at: number;
  related_permissions: PermissionRule[];
  tags: Tag[];
  companyName?: string;
  assistedById?: number;
  assistedByEmail?: string;
  installerAccess: InstallerAccess;
  showSosButton: boolean;
  status: SystemStatus | null;
  fw_version: string;
  sos_type: SosType;
  troubles: null | SystemTrouble[];
};

export type InstallerAccess = { id: number; installer_id: number; system_id: number; transferred: number; access_until: string; issue: string; timeLeft: number };

export type WithLastSystem = {
  lastSystem: SystemData | null;
};

export type HistorySystem = {
  id: number;
  installer_id: number;
  name: string;
  address: string;
  system_imei: string;
  primary_installer: number;
};

export type Region = {
  id: number;
  name: string;
  api_host: string;
  api_path: string;
  region_version: number;
  api_host_to_use?: string;
};

export type Chip = {
  name: string;
  label: string;
  value: boolean;
  modifiableColumn: boolean; // Kriterijus, kurio arba nėra tarp stulpelių, arba stulpelio negalima įjungti/išjungti. true - galima įjungti/išjungti.
};

export enum WidgetDataType {
  Area,
  Output,
  Sensor,
  Zone,
}

export type WidgetAreaData = {
  areaId: number;
  name: string;
  iconNumber: string;
  areaAlarmed: boolean;
  areaBoundToPgm: boolean;
  pgmEnabled: boolean;
  noStaySleep: boolean;
  useAway: boolean;
  systemId: number;
  systemName: string;
  isSosSystem: boolean;
}

export type WidgetOutputData = {
  pgmId: number;
  name: string;
  pgmState: boolean;
  iconNumber: string;
  systemId: number;
  systemName: string;
}

export interface RouteData extends Data {
  /**
   * Animacija tarp langų. Nenurodžius bus naudojamas "fade" efektas.
   * Jeigu einama iš lango su mažesne animacijos reikšme į langą su didesne reikšme - naujas langas 'įvažiuos' iš dešinės.
   * Jeigu einama iš lango su didesne animacijos reikšme į langą su mažesne reikšme - naujas langas 'įvažiuos' iš kairės.
   */
  animation?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  /**
   * Papildomi duomenys reikalingi PermissionGuard veikimui. Nurodo kokią taisyklę reikia tikrinti.
   */
  rule?: keyof PermissionList;
  /**
   * Papildomi duomenys reikalingi PermissionGuard veikimui.
   * Nurodo kokį prieigos lygį reikia tikrinti. Naudojama kartu su "rule" reikšme.
   * Nenurodžius - naudojama 'view' reikšmė.
   */
  accessLevel?: keyof Permission;
  /**
   * Nišinis parametras. Naudojamas tik tam, kad HomeComponent žinotų, jog reikia atlikti redirect į
   * vartotojo Home be subpath.
   */
  bare?: boolean;
  /**
   * Papildomi duomenys reikalingi PermissionGuard veikimui. Nurodo kokias taisykles reikia tikrinti.
   */
  rules?: (keyof PermissionList)[];
  /**
   * Papildomi duomenys reikalingi PermissionGuard veikimui.
   * Nurodo kokį prieigos lygį reikia tikrinti. Naudojama kartu su "rules" reikšme.
   * Nenurodžius - naudojama 'view' reikšmė.
   * Įrašo indeksas atitinka taisyklės pavadinimo įrašą "rules" reikšmėje.
   */
  accessLevels?: (keyof Permission)[];
  /**
   * Papildomi duomenys reikalingi PermissionGuard veikimui.
   * Nurodo kaip reikia tikrinti "rules" nurodytas taisykles.
   */
  permissionMatch?: PermissionMatchCriteria;
}

export type Routes = Route[];
export interface Route extends NgRoute {
  data?: RouteData;
  children?: Routes;
}

export enum PermissionMatchCriteria {
  ShouldMatchAll,
  AtLeastOneMatches,
}

type FilteredResultsPageData = {currentPage: number; lastPage: number};
export interface FilterCriteria extends FilteredResultsPageData {
  searchPhrase: string;
  searchFields: string[];
}