import { Injectable } from '@angular/core';
import { SystemsService } from './systems.service';
import { ToasterService } from './toaster.service';
import { MiniStatusService } from './mini-status.service';
import { ApiRequestService } from './api-request.service';
import { LanguageService } from './language.service';
import { TAreaStatusOperation } from '../models/area-status-operation';
import { TAreaData } from '../models/area-data';
import { RefreshService } from './refresh.service';
import { Subject } from 'rxjs';
import { ZoneService } from './zone.service';
import { WebSocketService } from './websocket.service';
import { GeneralSettings } from 'src/general-settings';
import { LoggerService } from '../api/logger.service';
import { AlarmType, AreaState, SetAreaStatusResponse, areaStateValues } from 'src/api/v3/system.area';
import { SystemService } from '../api/system/system.service';
import requests from 'src/api/v3/requests';
import { Colors } from 'src/assets/css/instance-colors';
import { RequestService } from '../api/request.service';

@Injectable({
  providedIn: 'root',
})
export class AreaService {
  private controlsEnabled = true;
  private areaStatusesInProgress = [];
  private areaStatusWorker = null;
  private tag = 'AreaService';
  public alarmedActionInProgress = false;
  private onAreaOperationCompletedSource = new Subject<void>();
  public onAreaOperationCompleted = this.onAreaOperationCompletedSource.asObservable();
  private onTakeActionButtonVisibilityChangedSource = new Subject<any>();
  public onTakeActionButtonVisibilityChanged = this.onTakeActionButtonVisibilityChangedSource.asObservable();
  private takeActionAreas = [];

  public busyArea: TAreaStatusOperation = null;

  constructor(
    private systems: SystemsService,
    private toaster: ToasterService,
    private miniStatus: MiniStatusService,
    private api: ApiRequestService,
    private ls: LanguageService,
    private l: LoggerService,
    private r: RefreshService,
    private zs: ZoneService,
    private systemService: SystemService,
    private requestService: RequestService,
    ws: WebSocketService
  ) {
    this.l.log('+', this.tag);
    //this.l.disableLogginForTag(this.tag);
    const that = this;
    this.systems.addAreaStatusWork = (systemId, areaId, statusTime) => {
      that.addAreaStatusInProgress(systemId, areaId, statusTime);
    };
    ws.onAreaStatusChange.subscribe((area: any) => {
      that.addOrUpdateAreaForTakeAction(area);
    });
  }

  public async changeAreaStateTo() {
    if (!this.controlsEnabled) {
      return;
    }
    this.controlsEnabled = false;
    const that = this;
    try {
      const result:SetAreaStatusResponse = await this.requestService.runSequentially(requests.system.area.changeStatus({
        area_id: this.busyArea.areaId,
        new_state: this.busyArea.newStatus,
        old_state: this.busyArea.oldStatus,
        pin: this.busyArea.pinToUse,
        remember_pin: this.busyArea.newRememberPin,
      }));
      that.l.log('', that.tag, result);
      that.controlsEnabled = true;
      that.miniStatus.hide();
      that.alarmedActionInProgress = false;
      that.onAreaOperationCompletedSource.next();
      if ( !result.success && result.badPin && !that.busyArea.showPinKeypad ) {
        that.toaster.postError(result.error);
        const system = that.systemService.systems.get(that.busyArea.systemId) || that.systems.getSystem(that.busyArea.systemId);
        if ( system ) {
          const area = system.areas.find(a => a.id = that.busyArea.areaId);
          if ( area ) {
            area.showKeypad = true;
          }
        }
        that.busyArea.showPinKeypad = true;
        return; // Parodys pin įvedimo lauką, tada jeigu bus Confirm, tai grįš į changeAreaStateTo.
      }
      if (result.success) {
        if (result.state !== undefined) {
          that.saveNewStatus(result.state);
          that.systems.setCurrentSystem(that.systems.activeSystem);
        } else {
          that.busyArea = null;
          that.r.enableRefresher();
        }
      } else if (result.failedZones !== undefined && Object.keys(result.failedZones).length > 0) {
        that.failedToChangeStatus(result);
      } else if (result.error !== undefined) {
        that.toaster.postError(result.error);
        that.failedToChangeStatus(result);
      } else {
        that.failedToChangeStatus(result);
      }
    } catch (e) {
      that.alarmedActionInProgress = false;
      that.onAreaOperationCompletedSource.next();
      that.miniStatus.hide();
      that.controlsEnabled = true;
      that.revertAreaStatus();
    }
  }

  public resetStatusAreaAlarm(area_ids: number[]) {
    if(area_ids.length === 0) { return; }
    const that = this;
    requests.system.area.muteAlarms({
      area_ids
    }).subscribe((result) => {
      if(result.success) {
        that.onTakeActionButtonVisibilityChangedSource.next({
          id: area_ids[0],
          visible: false,
        });
      } else {
        that.toaster.postError(result.error);
      }
    }, (error) => {
      that.toaster.postError(error);
    });
  }

  private failedToChangeStatus(result?: any) {
    if (result !== undefined && result.failedZones !== undefined) {
      const zoneNumbers = Object.keys(result.failedZones);
      this.busyArea.zonesToBypass = [];
      for (const iNumber of zoneNumbers) {
        this.busyArea.zonesToBypass.push({
          isChecked: false,
          // eslint-disable-next-line id-blacklist
          number: iNumber,
          title: result.failedZones[iNumber],
        });
      }
      this.busyArea.showZoneBypass = true;
      this.l.log('', this.tag, this.busyArea.zonesToBypass);
    } else {
      this.revertAreaStatus(result);
    }
  }

  public showButtons(areaId: number): boolean {
    return this.systems.activeSystem.directControl || this.isOutputAssigned(areaId);
  }

  public isOutputAssigned(areaId: number): boolean {
    const area = this.systems.activeSystem.areas.find((a) => a.id === areaId);
    if (area === undefined) {
      return false;
    }
    for (const iPgm of this.systems.activeSystem.pgms) {
      if (iPgm.area_no === area.queue_no) {
        return true;
      }
    }

    return false;
  }

  public addAreaStatusInProgress(systemId: number, areaId: number, statusTime: number) {
    let found = false;
    for (const iItem of this.areaStatusesInProgress) {
      if (iItem.areaId === areaId) {
        iItem.statusTime = new Date(statusTime * 1000);
        found = true;
        break;
      }
    }
    if (!found) {
      this.areaStatusesInProgress.push({
        systemId,
        areaId,
        statusTime: new Date(statusTime * 1000),
      });
    }
    if (this.areaStatusWorker === null) {
      const that = this;
      this.areaStatusWorker = setInterval(() => {
        that.areaStatusCheckTick();
      }, 5000);
    }
  }

  private areaStatusCheckTick() {
    for (const iItem of this.areaStatusesInProgress) {
      const system = this.systems.getSystem(iItem.systemId) || this.systemService.systems.get(iItem.systemId);
      if (system === undefined) {
        this.l.log('sistema daugiau neegzistuoja. salinam srities irasa.', this.tag);
        this.areaStatusesInProgress = this.areaStatusesInProgress.filter((i) => i.systemId !== iItem.systemId);
        return; // Neinam per kitus. Po 5 sekundžių vėl gįšim ir patikrinsim juos.
      }
      const area = system.areas.find((a) => a.id === iItem.areaId);
      if (area === undefined) {
        this.l.log('Sritis daugiau neegzistuoja. salinam srities irasa.', this.tag);
        this.areaStatusesInProgress = this.areaStatusesInProgress.filter((i) => i.areaId !== iItem.areaId);
        return; // Neinam per kitus. Po 5 sekundžių vėl gįšim ir patikrinsim juos.
      }
      if (area.status !== AreaState.ArmingInProgress && area.status !== AreaState.DisarmingInProgress) {
        this.l.log('srities busena jau nebe "changing", galima pasalinti.', this.tag);
        this.areaStatusesInProgress = this.areaStatusesInProgress.filter((i) => i.areaId !== iItem.areaId);
        return;
      }

      const now = new Date();
      if (now.getTime() - iItem.statusTime.getTime() >= 120000) {
        this.l.log('srities busena nepasikeite per nustatyta laika', this.tag);
        this.l.log('ERROR ^^', this.tag);
        area.status = AreaState.Unknown;
        this.areaStatusesInProgress = this.areaStatusesInProgress.filter((i) => i.areaId !== iItem.areaId);
        // this.toaster.postError(this.ls.get('systems.errors.areaStatusTimeout'));
        return; // Neinam per kitus. Po 5 sekundžių vėl gįšim ir patikrinsim juos.
      }
    }
  }

  public saveNewStatus(stateFromBackend: AreaState) {
    if ( !this.busyArea ) { return; }
    const system = this.systems.getSystem(this.busyArea.systemId) || this.systemService.systems.get(this.busyArea.systemId);
    if (system === undefined) {
      this.busyArea = null;
      this.r.enableRefresher();
      return;
    }
    const area = system.areas.find((a) => a.id === this.busyArea.areaId);
    if (area === undefined) {
      this.busyArea = null;
      this.r.enableRefresher();
      return;
    }
    area.status = stateFromBackend;
    if (this.busyArea.oldRememberPin !== this.busyArea.newRememberPin) {
      area.showKeypad = !this.busyArea.newRememberPin;
    }
    if (this.systems.activeSystem.id === this.busyArea.systemId) {
      this.systems.saveActiveSystem();
    }
    this.busyArea = null;
    this.r.enableRefresher();
  }

  public revertAreaStatus(result?: { badPin?: boolean }) {
    if ( !this.busyArea ) { // kol grįžo klaida, vartotojas matyt kažkur kitur nuėjo ir ta sritis tapo neaktuali.
      this.r.enableRefresher();
      return;
    }
    const system = this.systems.getSystem(this.busyArea.systemId) || this.systemService.systems.get(this.busyArea.systemId);
    if (system === undefined) {
      this.busyArea = null;
      this.r.enableRefresher();
      return;
    }
    const area = system.areas.find((a) => a.id === this.busyArea.areaId);
    if (area === undefined) {
      this.busyArea = null;
      this.r.enableRefresher();
      return;
    }
    area.status = this.busyArea.oldStatus;
    area.showKeypad = !this.busyArea.oldRememberPin || result?.badPin;
    if (this.systems.activeSystem.id === this.busyArea.systemId) {
      this.systems.saveActiveSystem();
    }
    this.busyArea = null;
    this.r.enableRefresher();
  }

  public isBusy(): boolean {
    return !this.controlsEnabled || this.busyArea !== null;
  }

  public getIconByAreaStatus(status: AreaState, alarmed: boolean, alarmType: AlarmType) {
    if ([AreaState.Disarmed, AreaState.DisarmedNotReady, AreaState.DisarmedReady].includes(status)) {
      if (alarmed) {
        return alarmType === AlarmType.Fire ? 'assets/images/fire_color.svg' : 'assets/images/area_alarmed_color.svg';
      } else {
        return 'assets/images/disarm.svg';
      }
    } else if (status === AreaState.Armed) {
      if (alarmed) {
        return alarmType === AlarmType.Fire ? 'assets/images/fire_color.svg' : 'assets/images/area_alarmed_color.svg';
      } else {
        return 'assets/images/arm.svg';
      }
    } else if (status === AreaState.Stay) {
      if (alarmed) {
        return alarmType === AlarmType.Fire ? 'assets/images/fire_color.svg' : 'assets/images/area_alarmed_color.svg';
      } else {
        return 'assets/images/stay.svg';
      }
    } else if (status === AreaState.Sleep) {
      if (alarmed) {
        return alarmType === AlarmType.Fire ? 'assets/images/fire_color.svg' : 'assets/images/area_alarmed_color.svg';
      } else {
        return 'assets/images/sleep.svg';
      }
    } else if ( status === AreaState.Unknown ) {
      return 'assets/images/question_cc.svg';
    }

    return '';
  }

  public getIconByAreaStatusEx(area: TAreaData) {
    if ([AreaState.Disarmed, AreaState.DisarmedNotReady, AreaState.DisarmedReady].includes(area.status)) {
      if (area.alarmed) {
        return 'assets/images/disarm_alarm.svg';
      } else {
        return 'assets/images/disarm.svg';
      }
    } else if (area.status === AreaState.Armed) {
      if (area.alarmed) {
        return 'assets/images/arm_alarm.svg';
      } else {
        return 'assets/images/arm.svg';
      }
    } else if (area.status === AreaState.Stay) {
      if (area.alarmed) {
        return 'assets/images/stay_alarm.svg';
      } else {
        return 'assets/images/stay.svg';
      }
    } else if (area.status === AreaState.Sleep) {
      if (area.alarmed) {
        return 'assets/images/sleep_alarm.svg';
      } else {
        return 'assets/images/sleep.svg';
      }
    } else if ( area.status === AreaState.Unknown ) {
      return 'assets/images/question_cc.svg';
    }

    return '';
  }

  public getAreaStatusColor(status: AreaState, alarmed: boolean) {
    if ( alarmed ) {
      return Colors.areaStates.alarmed;
    }
    if ([AreaState.Disarmed, AreaState.DisarmedNotReady, AreaState.DisarmedReady].includes(status)) {
      return Colors.areaStates.disarmed;
    } else if (status === AreaState.Armed) {
      return Colors.areaStates.armed;
    } else if (status === AreaState.Stay) {
      return Colors.areaStates.armed;
    } else if (status === AreaState.Sleep) {
      return Colors.areaStates.armed;
    } else if ( status === AreaState.Unknown ) {
      return Colors.areaStates.unknown;
    } else if ( status === AreaState.ArmingInProgress ) {
      return Colors.areaStates.armingInProgress;
    } else if ( status === AreaState.DisarmingInProgress ) {
      return Colors.areaStates.disarmingInProgress;
    }

    return '#000000';
  }

  public getDescriptionForStatus(status: AreaState, alarmed: boolean, area?: TAreaData) {
    if (area === undefined || status === area.status) {
      if (status === AreaState.Unknown) {
        return this.ls.get('systems.statuses.area.unknown');
      } else if ([AreaState.Disarmed, AreaState.DisarmedNotReady, AreaState.DisarmedReady].includes(status)) {
        return this.ls.get('systems.statuses.area.off');
      } else if (alarmed) {
        return this.ls.get('systems.statuses.area.alarm');
      } else if (status === AreaState.Armed) {
        return this.ls.get('systems.statuses.area.arm');
      } else if (status === AreaState.Stay) {
        return this.ls.get('systems.statuses.area.stay');
      } else if (status === AreaState.Sleep && !this.systems.activeSystemHelper.supports.away()) {
        return this.ls.get('systems.statuses.area.sleep');
      } else if (status === AreaState.Sleep) {
        return this.ls.get('systems.statuses.area.away');
      } else if (status === AreaState.ArmingInProgress) {
        return this.ls.get('systems.statuses.area.armInProgress');
      } else if (status === AreaState.DisarmingInProgress) {
        return this.ls.get('systems.statuses.area.disarmInProgress');
      }
    } else {
      if ((area.status === AreaState.ArmingInProgress || area.status === AreaState.DisarmingInProgress) && this.busyArea && this.busyArea.newStatus === status) {
        return '';
      }
      if ( !this.busyArea && ((area.status === AreaState.ArmingInProgress && status === AreaState.Armed) || (area.status === AreaState.DisarmingInProgress && [AreaState.Disarmed, AreaState.DisarmedNotReady, AreaState.DisarmedReady].includes(status))) ) {
        return '';
      }
      if (status === AreaState.Unknown) {
        return this.ls.get('systems.statuses.area.unknown');
      } else if ([AreaState.Disarmed, AreaState.DisarmedNotReady, AreaState.DisarmedReady].includes(status)) {
        return this.ls.get('systems.statuses.area.setOff');
      } else if (status === AreaState.Armed) {
        return this.ls.get('systems.statuses.area.setArm');
      } else if (status === AreaState.Stay) {
        return this.ls.get('systems.statuses.area.setStay');
      } else if (status === AreaState.Sleep && !this.systems.activeSystemHelper.supports.away()) {
        return this.ls.get('systems.statuses.area.setSleep');
      } else if (status === AreaState.Sleep) {
        return this.ls.get('systems.statuses.area.setAway');
      }
    }
  }

  public shouldShowLoading(status: AreaState, area?: TAreaData) {
    if (!area) {
      return false;
    }
    if ( !this.busyArea && (area.status === AreaState.ArmingInProgress || area.status === AreaState.DisarmingInProgress)) {
      return (area.status === AreaState.ArmingInProgress && status === AreaState.Armed ) || (area.status === AreaState.DisarmingInProgress && [AreaState.Disarmed, AreaState.DisarmedNotReady, AreaState.DisarmedReady].includes(status));
    }
    if ( !this.busyArea ) {
      return false;
    }
    if (this.busyArea.areaId !== area.id) {
      return false;
    }

    return this.busyArea.newStatus === status && (area.status === AreaState.ArmingInProgress || area.status === AreaState.DisarmingInProgress);
  }

  public loadingIsShown(area?: TAreaData) {
    if (!area) {
      return false;
    }
    if ( !this.busyArea && (area.status === AreaState.ArmingInProgress || area.status === AreaState.DisarmingInProgress)) {
      return true;
    }
    if ( !this.busyArea ) {
      return false;
    }
    if (this.busyArea.areaId !== area.id) {
      return false;
    }

    return this.busyArea.newStatus !== AreaState.Unknown && (area.status === AreaState.ArmingInProgress || area.status === AreaState.DisarmingInProgress);
  }

  public keypadCancelled() {
    if (this.busyArea === null) {
      return;
    }
    this.l.log('PIN lange paspausta Cancel', this.tag);
    this.revertAreaStatus();
    this.alarmedActionInProgress = false;
  }

  public pinConfirmed(pin: string, remember: boolean) {
    if (this.busyArea === null) {
      return;
    }
    this.l.log('Pin patvirtintas', this.tag);
    this.busyArea.pinToUse = pin;
    this.busyArea.newRememberPin = remember;
    this.busyArea.showPinKeypad = false;
    this.performStatusChange();
  }

  public performStatusChange() {
    if (this.busyArea === null) {
      return;
    }
    const system = this.systems.getSystem(this.busyArea.systemId) || this.systemService.systems.get(this.busyArea.systemId);
    if (system === undefined) {
      this.busyArea = null;
      this.r.enableRefresher();
      this.alarmedActionInProgress = false;
      return;
    }
    const area = system.areas.find((a) => a.id === this.busyArea.areaId);
    if (area === undefined) {
      this.busyArea = null;
      this.r.enableRefresher();
      this.alarmedActionInProgress = false;
      return;
    }
    if ( this.busyArea.newStatus === AreaState.Disarmed ) {
      area.status = AreaState.DisarmingInProgress;
    } else {
      area.status = AreaState.ArmingInProgress;
    }
    this.addAreaStatusInProgress(this.busyArea.systemId, this.busyArea.areaId, new Date().getTime() / 1000);
    this.changeAreaStateTo();
  }

  public zoneBypassCancelled() {
    if (this.busyArea === null) {
      return;
    }
    this.l.log('Zonų bypass lange paspausta Cancel', this.tag);
    this.revertAreaStatus();
    this.alarmedActionInProgress = false;
  }

  public zoneBypassConfirmed() {
    if (this.busyArea === null) {
      return;
    }
    this.l.log('Zonų bypass bandomas vykdyti', this.tag);
    this.busyArea.showZoneBypass = false;
    const that = this;
    const zones = [];
    for (const iZone of this.busyArea.zonesToBypass) {
      if (!iZone.isChecked) {
        continue;
      }
      zones.push(iZone.number);
    }
    if ( zones.length === 0 ) {
      this.toaster.postError('systems.errors.mustSelectZonesToBypass');
      this.revertAreaStatus();
      return;
    }
    this.zs.performMultiBypass(
      this.busyArea.systemId,
      this.busyArea.areaId,
      zones,
      this.busyArea.pinToUse,
      this.busyArea.newRememberPin,
      () => {
        that.performStatusChange();
      },
      () => {
        that.revertAreaStatus();
        that.alarmedActionInProgress = false;
      }
    );
  }

  /**
   * Prideda arba atnaujina srities duomenis susijusius su Take Action funkcionalumu. Taip pat paleidžiamas taimeris.
   *
   * @param area Area iš web socket arba TAreaData
   */
  public addOrUpdateAreaForTakeAction(area: any | TAreaData) {
    let found = this.takeActionAreas.find((ta) => ta.id === area.id);
    const originatesFromWebsocket = area.hasOwnProperty('alarm_time');
    if (found === undefined) {
      this.l.log('Sukuriam "Take action" duomenis sriciai ' + area.id);
      found = {
        id: area.id,
        alarmTime: originatesFromWebsocket ? area.alarm_time : area.alarmTime,
        alarmed: originatesFromWebsocket ? area.alarm_in_memory : area.alarmed,
        hideTakeActionTimer: null,
      };
      this.takeActionAreas.push(found);
    } else {
      this.l.log('Atnaujinam "Take action" duomenis sriciai ' + area.id);
      found.alarmTime = originatesFromWebsocket ? area.alarm_time : area.alarmTime;
      found.alarmed = originatesFromWebsocket ? area.alarm_in_memory : area.alarmed;
      if (found.hideTakeActionTimer !== null) {
        clearTimeout(found.hideTakeActionTimer);
        found.hideTakeActionTimer = null;
      }
    }
    this.checkIfAlarmButtonShouldBeShown(found);
  }

  private checkIfAlarmButtonShouldBeShown(takeActionArea: any) {
    if (takeActionArea.alarmTime === 0) {
      this.l.log('"Take action" nerodomas ' + takeActionArea.id + ', nes nera duomenu apie aliarma.');
      this.onTakeActionButtonVisibilityChangedSource.next({
        id: takeActionArea.id,
        visible: false,
      });
      return;
    }
    const nowTime = new Date().getTime();
    const alarmTime = takeActionArea.alarmTime * 1000;
    const showAlarmButton =
      takeActionArea.alarmed &&
      (this.systems.activeSystemHelper.supports.silenceSiren() || this.systems.activeSystemHelper.supports.cancelAlarm()) &&
      nowTime - alarmTime <= GeneralSettings.takeActionButtonDuration * 1000;
    if (!showAlarmButton) {
      this.l.log('"Take action" ' + takeActionArea.id + ' neturi buti rodomas.');
      this.l.log('Papildomi duomenys:', this.tag, { alarmed: takeActionArea.alarmed, nowTime, alarmTime, silence: this.systems.activeSystemHelper.supports.silenceSiren(), cancel: this.systems.activeSystemHelper.supports.cancelAlarm(), area: takeActionArea });
      this.onTakeActionButtonVisibilityChangedSource.next({
        id: takeActionArea.id,
        visible: false,
      });
      this.stopAlarmButtonTimer(takeActionArea);
    } else {
      this.l.log('Rodom "Take action" ' + takeActionArea.id + ' ir paleidziam taimeri paslepimui.');
      this.onTakeActionButtonVisibilityChangedSource.next({
        id: takeActionArea.id,
        visible: true,
      });
      this.stopAlarmButtonTimer(takeActionArea);
      const timeLeft = GeneralSettings.takeActionButtonDuration * 1000 - (nowTime - alarmTime);
      const that = this;
      takeActionArea.hideTakeActionTimer = setTimeout(() => {
        that.l.log('Atejo laikas paslepti "Take action" ' + takeActionArea.id + ' mygtuka');
        that.onTakeActionButtonVisibilityChangedSource.next({
          id: takeActionArea.id,
          visible: false,
        });
        that.stopAlarmButtonTimer(takeActionArea);
      }, timeLeft);
    }
    this.l.log('Praėjo laiko nuo paskutinės būsenos ' + takeActionArea.id + ': ' + (nowTime - alarmTime));
  }

  private stopAlarmButtonTimer(takeActionArea: any) {
    if (takeActionArea.hideTakeActionTimer !== null) {
      clearTimeout(takeActionArea.hideTakeActionTimer);
      takeActionArea.hideTakeActionTimer = null;
    }
  }
}
