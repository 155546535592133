import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoggerService } from '../api/logger.service';

@Injectable({
  providedIn: 'root',
})
export class ProgressService {
  private show = false;
  private currentProgress = 0;
  private maxProgress = 0;
  private onProgressChangeSource = new Subject<number>();
  public onProgressChange = this.onProgressChangeSource.asObservable();
  public progressType = 0;

  constructor(private l: LoggerService) {}

  public showProgress(currentValue?: number, maxValue?: number) {
    this.show = true;
    if (currentValue !== undefined && maxValue !== undefined) {
      this.setProgressData(currentValue, maxValue);
    }
  }

  /**
   *
   * @param currentValue Esama progreso reikšmė
   * @param maxValue Maksimali progreso reikšmė
   * @param progressType Tipas. 0 = mygtukų spalva; 1 = duomenų skaitymas; 2 = Customize langai
   */
  public setProgressData(currentValue: number, maxValue: number, progressType?: number) {
    this.l.log('progress data ', 'Progress', { currentValue, maxValue });
    this.currentProgress = currentValue;
    this.maxProgress = maxValue;
    const progressPercent = currentValue / maxValue;
    this.onProgressChangeSource.next(progressPercent * 100);
    if (progressType === undefined) {
      this.progressType = 0;
    } else {
      this.progressType = progressType;
    }
  }

  public isShowing(): boolean {
    return this.show;
  }

  public getMaxProgressValue(): number {
    return this.maxProgress;
  }

  public getCurrentProgressValue(): number {
    return this.currentProgress;
  }

  public hideProgress() {
    this.show = false;
  }
}
