<div *ngIf="!hasConsented" class="consent-page">
  <div>
    <h2>{{ trans('users.consent.header') }}</h2>
    <p>{{ trans('privacyPolicyFE.p_email') }}</p>
    <p>{{ trans('privacyPolicyFE.p_name') }}</p>
    <p>{{ trans('privacyPolicyFE.p_cameraData') }}</p>
    <p>{{ trans('privacyPolicyFE.p_deviceId') }}</p>
    <p>{{ trans('privacyPolicyFE.p_push') }}</p>
    <a href="javascript:void(0);" (click)="$event.preventDefault(); consent(); (false)">
      <div class="button bare-secondary">{{trans('users.buttons.accept')}}</div>
    </a>
  </div>
</div>
