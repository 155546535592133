import { ChangeDetectorRef, OnDestroy, Injector, AfterViewInit, Directive } from '@angular/core';
import { LanguageService } from '../services/language.service';
import { ProgressService } from '../services/progress.service';
import { HeaderService } from '../services/header.service';
import { FooterService } from '../services/footer.service';
import { BackgroundService } from '../services/background.service';
import { LocatorService } from '../services/locator.service';
import { MiniStatusService } from '../services/mini-status.service';
import { ToasterService } from '../services/toaster.service';
import { ApiRequestService } from '../services/api-request.service';
import { SystemsService } from '../services/systems.service';
import { HelperService } from '../services/helper.service';
import { RefreshService } from '../services/refresh.service';
import { GlobalService } from '../services/global.service';
import { LoggerService } from '../api/logger.service';
import { PlatformService } from '../api/platform.service';
import { UserService } from '../api/user.service';
import { PermissionService } from '../api/permission.service';
import { CompanyService } from '../api/company.service';
import { PersistenceService } from '../api/persistence.service';
import { TagService } from '../api/tag.service';
import { EditSystemService } from '../services/edit-system.service';
import { ZoneService } from '../api/system/zone.service';
import { AreaService } from '../api/system/area.service';
import { IpcomService } from '../api/ipcom.service';
import { DeviceSetupTemplateService } from '../services/device-setup-template.service';
import { TBackgroundType } from '../models/background-types';
import { SystemService } from '../api/system/system.service';
import { RequestService } from '../api/request.service';

type NewType = PlatformService;
type GeneralOptions = {
  /**
   * Nurodo ar reikia NEvykdyti fono spalvos keitimą ir header, footer, progress paslėpimą.
   */
  noVisualModifications?: boolean;
  /**
   * Nurodo ar atidarius langą su šiuo komponentu bus gražinama į viršų.
   */
  shouldScrollTop?: boolean;
  /**
   * Nurodo kokį foną reikia naudoti šiam langui.
   */
  backgroundType?: TBackgroundType;
};

/**
 * Klasė, kuri _žino_ kada pasikeitė vertimai, tuomet automatiškai perpiešia langą.
 */
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class LanguageAware implements OnDestroy, AfterViewInit {
  protected cdRef: ChangeDetectorRef;
  public ls: LanguageService;
  protected progressBar: ProgressService;
  protected headerBar: HeaderService;
  protected footerBar: FooterService;
  protected background: BackgroundService;
  public miniStatus: MiniStatusService;
  protected toaster: ToasterService;
  public us: UserService;
  protected api: ApiRequestService;
  public platform: NewType;
  public systems: SystemsService;
  public helper: HelperService;
  private translationSubscription = null;
  public l: LoggerService;
  public refresher: RefreshService;
  public g: GlobalService;
  protected tag = '';
  private shouldScrollToTop = true;
  public get permissionService() { return LocatorService.injector.get(PermissionService); }
  public get companyService() { return LocatorService.injector.get(CompanyService); }
  public get persistenceService() { return LocatorService.injector.get(PersistenceService); }
  public get tagService() { return LocatorService.injector.get(TagService); }
  public get editSystemService() { return LocatorService.injector.get(EditSystemService); }
  public get systemZoneService() { return LocatorService.injector.get(ZoneService); }
  public get systemAreaService() { return LocatorService.injector.get(AreaService); }
  public get ipcomService() { return LocatorService.injector.get(IpcomService); }
  public get deviceSetupTemplateService() { return LocatorService.injector.get(DeviceSetupTemplateService); }
  public get systemService() { return LocatorService.injector.get(SystemService); }
  public get requestService() { return LocatorService.injector.get(RequestService); }
  /**
   * Konstruktorius. Paslepia header'į, footer'į, progressBar'ą. Pakeičia foną į baltą.
   * Klasėje, kuri praplečia šią klasę, __constructor__'iaus parametruose turi būti
   * _cdRef: ChangeDetectorRef_, __BE__ jokių _scope_ modifikatorių tokių kaip __private__,__public__ ar __protected__.
   *
   * @param changeDetector _ChangeDetectorRef_: UI pasikeitimų valdymo objektas.
   * @param noVisualModifications _boolean_: Nurodo ar reikia vykdyti fono spalvos keitimą ir header, footer, progress paslėpimą.
   *
   * __DĖMESIO:__ Jeigu tai yra sub komponentas, kuris naudojamas kažkieno kito viduje, tai __būtina__ naudoti noVisualModifications
   * ir nustatyti reikšmę _true_, kitu atveju bus klaida "Value has changed after it was checked"
   */
  constructor(changeDetector: ChangeDetectorRef, options?: GeneralOptions) {
    this.cdRef = changeDetector;
    const that = this;
    this.ls = LocatorService.injector.get(LanguageService);
    this.progressBar = LocatorService.injector.get(ProgressService);
    this.headerBar = LocatorService.injector.get(HeaderService);
    this.footerBar = LocatorService.injector.get(FooterService);
    this.background = LocatorService.injector.get(BackgroundService);
    this.miniStatus = LocatorService.injector.get(MiniStatusService);
    this.toaster = LocatorService.injector.get(ToasterService);
    this.us = LocatorService.injector.get(UserService);
    this.api = LocatorService.injector.get(ApiRequestService);
    this.platform = LocatorService.injector.get(PlatformService);
    this.systems = LocatorService.injector.get(SystemsService);
    this.helper = LocatorService.injector.get(HelperService);
    this.l = LocatorService.injector.get(LoggerService);
    this.refresher = LocatorService.injector.get(RefreshService);
    this.g = LocatorService.injector.get(GlobalService);
    this.translationSubscription = this.ls.onTranslationsChange.subscribe(() => {
      that.translationChanged();
    });
    if (options?.noVisualModifications === undefined || !options.noVisualModifications) {
      this.progressBar.hideProgress();
      this.headerBar.hideHeader();
      this.footerBar.hideFooter();
      this.background.setBackground(options?.backgroundType ?? TBackgroundType.White);
      this.headerBar.onBackClicked = null;
      this.headerBar.onActionButtonClicked = null;
      this.headerBar.onXButtonClicked = null;
      this.refresher.disableRefresher();
    }
    this.shouldScrollToTop = options?.shouldScrollTop === undefined || options.shouldScrollTop;
  }

  private translationChanged() {
    this.cdRef.markForCheck();
  }

  ngOnDestroy() {
    this.translationSubscription.unsubscribe();
  }

  /**
   * Nurodo jog langas turi buti perpieštas.
   */
  protected refreshWindow() {
    this.cdRef.markForCheck();
  }

  /**
   * Ieškomas vertimas pagal pateiktą raktažodį.
   *
   * @param keyword _string_: Raktažodis, kurio vertimą reikia gauti.
   */
  public trans(keyword) {
    return this.ls.get(keyword);
  }

  public log(msg: string, obj?: any) {
    this.l.log(msg, this.tag, obj);
  }

  ngAfterViewInit() {
    if (!this.shouldScrollToTop) {
      return;
    }
    const content = document.querySelector('#content');
    if (content) {
      content.scroll(0, 0);
    }
  }
}
