import { CommonModule } from '@angular/common';
import { AfterViewInit, ApplicationRef, Component, ComponentRef, ElementRef, EventEmitter, HostListener, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { BasePopupComponent } from '../base-popup/base-popup.component';
import { TCamera } from 'src/app/models/camera';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PgmData } from 'src/app/models/pgm';
import { PgmButtonComponent } from 'src/app/components/pgm-button/pgm-button.component';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { SystemsService } from 'src/app/services/systems.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-camera-view',
  templateUrl: './camera-view.component.html',
  styleUrls: ['./camera-view.component.scss'],
  standalone: true,
  imports: [CommonModule, BasePopupComponent, PgmButtonComponent, InlineSVGModule],
})
export class CameraViewComponent implements AfterViewInit{
  public cameraViewComponentRef: ComponentRef<CameraViewComponent>;
  public cameras: TCamera[] | undefined;
  @Output() cameraViewClose: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('basePopup') basePopup: BasePopupComponent;
  @ViewChildren('videoStream') videoStreams: QueryList<ElementRef<HTMLIFrameElement>>;
  public canSeeOutputs: boolean;

  constructor(
    private appRef: ApplicationRef,
    private sanitizer: DomSanitizer,
    public systems: SystemsService,
    public lang: LanguageService,
  ) {
    this.canSeeOutputs = this.systems.activeSystemHelper.can.see.outputs();
  }

  ngAfterViewInit() {
    if(this.videoStreams) {
      this.videoStreams.forEach((videoStream, index) => {
        videoStream.nativeElement.src = this.cameras[index].fullUrl;
      });
    }
  }

  public basePopupCancelled() {
    this.cameraViewClose.emit(true);
    this.removeCameraFromView();
  }

  public clickCancel() {
    this.cameraViewClose.emit(true);
    this.removeCameraFromView();
  }

  private removeCameraFromView(): void {
    if(this.cameraViewComponentRef) {
      this.appRef.detachView(this.cameraViewComponentRef.hostView);
      this.cameraViewComponentRef.destroy();
    }
  }

  public getSafeCameraUrl(camera: TCamera): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(camera.fullUrl);
  }

  public getAssignedPgms(camera: TCamera): PgmData[] {
    if(!this.systems.activeSystem) { return; }
    const visibleOutputs = [];
    for (const iPgm of camera.assignedPgms) {
      const pgm = this.systems.activeSystem.pgms.find((p) => p.queue_no === iPgm);
      if (pgm !== undefined) {
        visibleOutputs.push(pgm);
      }
    }
    return visibleOutputs;
  }

  @HostListener('document:keydown.escape')
  onEscapeClick() {
    this.clickCancel();
  }

}
