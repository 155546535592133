<router-outlet></router-outlet>
<app-consent></app-consent>
<div class="root-loader" [style.display]="isLoading ? 'block' : 'none'">
  <div id="start-screen-container">
    <div id="start-screen-logo-loader">
      <svg id="start-screen-logo-spinner-a" viewBox="0 0 512 512" style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 2">
        <g clip-path="url(#_clip1)">
          <g transform="matrix(6.72651e-17,1.09852,-1.09852,6.72651e-17,542.507,-50.4433)">
            <path
              d="M254.513,29.043C262.545,28.201 270.702,27.771 278.96,27.771C407.578,27.771 512,132.193 512,260.811C512,269.441 511.53,277.963 510.607,286.368C497.691,285.642 487.457,274.895 487.518,261.813C487.52,261.479 487.521,261.145 487.521,260.811C487.521,145.703 394.068,52.25 278.96,52.25C265.876,52.25 255.176,41.963 254.513,29.043Z"
              style="fill: rgb(239, 71, 111)"
            />
          </g>
        </g>
      </svg>
      <svg id="start-screen-logo-spinner-b" viewBox="0 0 512 512" style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 2">
        <g clip-path="url(#_clip1)">
          <g transform="matrix(6.72651e-17,1.09852,-1.09852,6.72651e-17,542.507,-50.4433)">
            <path
              d="M252.292,492.339C253.076,479.425 263.873,469.239 276.956,469.363C277.623,469.369 278.291,469.372 278.96,469.372C394.068,469.372 487.521,375.919 487.521,260.811C487.521,145.703 394.068,52.25 278.96,52.25C265.876,52.25 255.176,41.963 254.513,29.043C262.545,28.201 270.702,27.771 278.96,27.771C407.578,27.771 512,132.193 512,260.811C512,389.429 407.578,493.851 278.96,493.851C269.943,493.851 261.046,493.338 252.292,492.339Z"
              style="fill: white"
            />
          </g>
        </g>
      </svg>
      <div class="start-screen-logo-text"></div>
    </div>
  </div>
  <div id="loading" class="loading-ok">Loading...</div>
</div>
<app-portal name="body"></app-portal>
