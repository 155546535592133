import { Injectable } from '@angular/core';
import { AppSettings } from 'src/app-settings';
import { AndroidHandler, AppleHandler } from 'src/window';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  constructor() {}
  /**
   * isDesktop
   */
  public isDesktop() {
    return !this.isMobile();
  }

  public isDesktopView() {
    return this.isDesktop() && AppSettings.companyDesktopEnabled;
  }

  /**
   * isMobile
   */
  public isMobile() {
    return this.isAndroid() || this.isApple();
  }

  /**
   * Pasako ar šiuo metu dirbama Android aplikacijoje.
   */
  public isAndroid() {
    return window.jsi !== undefined;
  }

  /**
   * Pasako ar šiuo metu dirbama iOS aplikacijoje.
   */
  public isApple() {
    return window.webkit !== undefined && this.hasAppleMessageHandlers();
  }

  /**
   * Gražina Android aplikacijos sukurtą JavascriptInterface objektą.
   * Per šį objektą galima bendrauti su Android Native dalimi.
   */
  public androidHandler(): AndroidHandler {
    if (!window.jsi) { throw new Error('Not an Android app'); }
    return window.jsi;
  }

  /**
   * Gražina Apple aplikacijos sukurtą MessageHandler objektą.
   * Per šį objektą galima bendrauti su iOS Native dalimi.
   */
  public appleHandler(): AppleHandler {
    if (!window.webkit) { throw new Error('Not an Apple app'); }
    return window.webkit.messageHandlers;
  }

  public hasAppleMessageHandlers(): boolean {
    return !!window.webkit.messageHandlers;
  }

  /**
   * Patikrina ar naršyklė yra Edge (jeigu tai įmanoma), ir jeigu pateiktas parametras `ofVersion`, bus patikrina ar
   * versija lygi arba aukštesnė.
   *
   * @param ofVersion Versija, kurią tikrinti.
   */
  public isEdgeBrowser(ofVersion?: number): boolean {
    const edge = window.navigator.userAgentData?.brands?.find(b => b.brand === 'Microsoft Edge');
    if ( !edge ) { return false; }
    if ( ofVersion !== undefined && !edge.version ) { return false; }
    const currentVersion = parseInt(edge.version, 10);
    if ( isNaN(currentVersion) ) { return false; }
    return ofVersion <= currentVersion;
  }
}

export const isApple = (): boolean => !!window.webkit && !!window.webkit.messageHandlers;
export const isAndroid = (): boolean => !!window.jsi;
export const isDesktop = (): boolean => !isApple() && !isAndroid();
