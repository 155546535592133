import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations';

const leftToRight = [
  style({ position: 'relative' }),
  query(
    ':enter, :leave',
    [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
      }),
    ],
    { optional: true }
  ),

  query(':enter', [style({ left: '-100%' })], { optional: true }),
  query(':leave', animateChild(), { optional: true }),
  group([
    query(':leave', [animate('200ms ease-out', style({ left: '100%', opacity: 0 }))], { optional: true }),
    query(':enter', [animate('300ms ease-out', style({ left: '0%' }))], {
      optional: true,
    }),
    query('@*', animateChild(), { optional: true }),
  ]),
];

const rightToLeft = [
  style({ position: 'relative' }),
  query(
    ':enter, :leave',
    [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%',
        'min-height': '100%',
      }),
    ],
    { optional: true }
  ),

  query(':enter', [style({ right: '-100%' })], { optional: true }),
  query(':leave', animateChild(), { optional: true }),
  group([
    query(':leave', [animate('200ms ease-out', style({ right: '100%', opacity: 0 }))], { optional: true }),
    query(':enter', [animate('300ms ease-out', style({ right: '0%' }))], {
      optional: true,
    }),
    query('@*', animateChild(), { optional: true }),
  ]),
];

const fade = [
  style({ position: 'relative' }),
  query(
    ':enter, :leave',
    [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%',
        'min-height': '100%',
      }),
    ],
    { optional: true }
  ),
  query(':enter', [style({ opacity: 0 })], { optional: true }),
  query(':leave', animateChild(), { optional: true }),
  group([
    query(':leave', [animate('200ms ease-out', style({ opacity: 0 }))], {
      optional: true,
    }),
    query(':enter', [animate('300ms ease-out', style({ opacity: 1 }))], {
      optional: true,
    }),
    query('@*', animateChild(), { optional: true }),
  ]),
];

const transitionCheck = (desiredSign: number) => (aS: string, bS: string) => {
  const a = Number(aS);
  const b = Number(bS);
  return !isNaN(a) && !isNaN(b) && Math.sign(a - b) === desiredSign;
};
// @ts-ignore
// const d = (fn) => (...a) => { console.log(`Transition: '${a[0]}' => '${a[1]}'`); return fn(...a) }
const d = (fn) => fn; // Do nothing

export const pageTransitionAnimation = trigger('pageTransition', [
  transition(d(transitionCheck(-1)), rightToLeft),
  transition(d(transitionCheck(1)), leftToRight),
  transition('* <=> *', fade),
]);
