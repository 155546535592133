<div class="white_round padding8" id="aiw_container">
    <div id="aiw_icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 39 39"><g transform="translate(-1441 -1085)"><circle cx="19.5" cy="19.5" r="19.5" transform="translate(1441 1085)"/><path d="M.941,19.95h.941V13.156H.941A.94.94,0,0,1,0,12.216V9.969a.94.94,0,0,1,.941-.941H6.207a.94.94,0,0,1,.941.941v9.98h.941a.94.94,0,0,1,.941.941v2.246a.94.94,0,0,1-.941.941H.941A.94.94,0,0,1,0,23.137V20.89A.94.94,0,0,1,.941,19.95ZM4.514,0A3.386,3.386,0,1,0,7.9,3.386,3.386,3.386,0,0,0,4.514,0Z" transform="translate(1455.985 1092.461)" fill="#fff"/></g></svg>
    </div>
    <div id="aiw_text_container">
        <div id="aiw_assist_text">{{trans('systems.labels.systemIsAssisted')}}:</div>
        <div id="aiw_assist_email">{{installerEmail}}</div>
    </div>
    <div id="aiw_revoke">
        <div *ngIf="showRevokeButton" class="button" (click)="initRevoke()">{{trans('users.buttons.revokeInstallerShort')}}</div>
    </div>
</div>