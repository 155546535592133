import { Injectable, NgZone } from '@angular/core';
import { ApiRequestService } from './api-request.service';
import { LanguageService } from './language.service';
import { LoggerService } from '../api/logger.service';
import { MiniStatusService } from './mini-status.service';
import { PlatformService } from '../api/platform.service';

@Injectable({
  providedIn: 'root',
})
export class AppleService {
  private callbackWhenSuccess = null;
  private callbackWhenFailure = null;

  constructor(
    private zone: NgZone,
    private miniStatus: MiniStatusService,
    private ps: PlatformService,
    private lang: LanguageService,
    private l: LoggerService,
    private api: ApiRequestService
  ) {
    const that = this;

    if (window.loginJsi !== null && window.loginJsi !== undefined) {
      window.loginJsi.onAppleCheck = (userId: string, email: string, name: string, authCode: string) => {
        zone.run(() => {
          that.onAppleLogin(userId, email, name, authCode);
        });
      };
      window.loginJsi.onAppleNoSupport = () => {
        that.appleLoginNotSupported();
      };
    } else {
      window.loginJsi = {
        onAppleCheck: (userId: string, email: string, name: string, authCode: string) => {
          zone.run(() => {
            this.onAppleLogin(userId, email, name, authCode);
          });
        },
        onAppleNoSupport: () => {
          this.appleLoginNotSupported();
        },
      };
    }
  }

  public performLogin(goodCallback, badCallback) {
    this.callbackWhenSuccess = goodCallback;
    this.callbackWhenFailure = badCallback;
    this.miniStatus.show(this.lang.get('auth.loggingIn'));
    this.ps.appleHandler().doAppleLogin.postMessage('');
  }

  /** Siunčiami Apple login duomenys, bei bandoma prisijungti */
  /** @param idToken Iš Google gautas vartotojo ID token. */
  /** @param accessToken Iš Google gautas vartotojo access token. */
  private onAppleLogin(userId: string, email: string, name: string, authCode: string) {
    const lastSystemId = localStorage.getItem('last_system');
    if (userId === '' && email === '') {
      this.miniStatus.hide();
      this.callbackWhenFailure(this.lang.get('auth.errors.appleNoToken'));
      return;
    }
    this.api.post('/check/apple', { userId, email, name, last_system: lastSystemId, authCode }, false).subscribe(
      (result) => {
        if (result.success) {
          this.zone.run(() => {
            this.miniStatus.hide();
            this.callbackWhenSuccess(result);
          });
        } else {
          this.zone.run(() => {
            this.miniStatus.hide();
            this.callbackWhenFailure(result.error);
          });
        }
      },
      (error) => {
        this.miniStatus.hide();
        this.callbackWhenFailure(this.lang.get('auth.errors.appleFailed'));
      }
    );
  }

  appleLoginNotSupported() {
    const that = this;
    this.zone.run(() => {
      that.miniStatus.hide();
      that.callbackWhenFailure(this.lang.get('auth.errors.appleSignInNotSupported'));
    });
  }
}
