<app-base-popup #basePopup *ngIf="cameras && cameras.length > 0" [canCancel]="true" [useDefaultContainerClass]="cameras.length === 1" (popupCancelled)="basePopupCancelled()">
  <div *ngIf="cameras && cameras.length === 1">
    <div class="camera_close_button" (click)="clickCancel()"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 13.426 13.423"><path d="M19.589,18l4.8-4.8A1.124,1.124,0,0,0,22.8,11.616l-4.8,4.8-4.8-4.8A1.124,1.124,0,1,0,11.616,13.2l4.8,4.8-4.8,4.8A1.124,1.124,0,0,0,13.2,24.384l4.8-4.8,4.8,4.8A1.124,1.124,0,1,0,24.384,22.8Z" transform="translate(-11.285 -11.289)" fill="#000000"/></svg></div>
    <div class="camera_header_text">{{cameras[0].name}}</div>
    <img class="camera_img" [src]="getSafeCameraUrl(cameras[0])" [alt]="cameras[0].name" />
    <ng-container *ngIf="systems.activeSystemHelper.can.see.outputs() && cameras[0].assignedPgms.length > 0">
      <div class='camera_text'>{{lang.get('systems.statuses.chooseAction')}}</div>
      <app-pgm-button *ngFor='let pgm of getAssignedPgms(cameras[0])' [pgm]='pgm' [buttonType]="'switch'"></app-pgm-button>
    </ng-container>
    <div class="camera_button_link"><div (click)="clickCancel()">{{ lang.get('general.close') }}</div></div>
  </div>
  <ng-container *ngIf="cameras && cameras.length > 1">
    <div class="closeIcon" [inlineSVG]="'assets/images/close.svg'" (click)="clickCancel()"></div>
    <div class="container container-grid">
      <div class="container-grid-item" *ngFor="let camera of cameras">
        <div class="container-grid-item-stream">
          <iframe #videoStream frameborder="0"></iframe>
        </div>
        <div *ngIf="canSeeOutputs && camera.assignedPgms.length > 0" class="container-grid-item-pgms">
          <app-pgm-button *ngFor="let pgm of getAssignedPgms(camera)" [pgm]="pgm" [buttonType]="'switch'"></app-pgm-button>
        </div>
      </div>
    </div>
  </ng-container>
</app-base-popup>
  