<div class="pp_overlay" (click)='clickCancel()'>
  <div class="pp_container_default" (click)="$event.stopPropagation()">
    <div id="msgb_close_button" (click)="clickCancel()" *ngIf="showCloseButton"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 13.426 13.423"><path d="M19.589,18l4.8-4.8A1.124,1.124,0,0,0,22.8,11.616l-4.8,4.8-4.8-4.8A1.124,1.124,0,1,0,11.616,13.2l4.8,4.8-4.8,4.8A1.124,1.124,0,0,0,13.2,24.384l4.8-4.8,4.8,4.8A1.124,1.124,0,1,0,24.384,22.8Z" transform="translate(-11.285 -11.289)" fill="#000000"/></svg></div>
    <div id="msgb_header_text" *ngIf="headerText !== ''">{{headerText}}</div>
    <div id="msgb_top_subtexts" class="msgb_subtext_container" *ngIf="subtextTopLeft || subtextTopRight">
      <div id="msgb_sub_tl" class="msgb_subtext" *ngIf="subtextTopLeft && subtextTopLeft !== ''">{{subtextTopLeft}}</div>
      <div id="msgb_sub_tr" class="msgb_subtext" *ngIf="subtextTopRight && subtextTopRight !== ''">{{subtextTopRight}}</div>
    </div>
    <div class="toaster_wrapper">
      <div *ngIf='iconType !== undefined' id="msgb_toaster">
        <div *ngIf='iconType === MessageBoxIcon.Info' id='msgb_toaster-info' [inlineSVG]="'assets/images/info.svg'"></div>
        <div *ngIf='iconType === MessageBoxIcon.Error' id='msgb_toaster-error' [inlineSVG]="'assets/images/error.svg'"></div>
        <div *ngIf='iconType === MessageBoxIcon.Success' id='msgb_toaster-success' [inlineSVG]="'assets/images/success.svg'"></div>
        <div *ngIf='iconType === MessageBoxIcon.Warning' id='msgb_toaster-warning' [inlineSVG]="'assets/images/warning.svg'"></div>
      </div>
    </div>
    <div id="msgb_text" #msgbText>
      <ng-container *ngTemplateOutlet="messageTpl; context: { content: messageContent }"></ng-container>
      <ng-template #messageTpl let-content="content">
        <div [ngClass]="{'center' : alignCenter}" [innerHTML]="content"></div>
      </ng-template>
      <ng-content></ng-content>
    </div>
    <div *ngIf="youtubeVideoId" class="youtube_video_container">
      <iframe width="100%" height="100%" [src]="youtubeVideoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
    <div *ngIf="checkboxes.length > 0" class="checkbox_container">
      <div  *ngFor="let check of checkboxes">
        <app-checkbox [checkValue]="check.checked" (valueChanged)="check.checked = !$event" [isRound]="false">{{check.label}}</app-checkbox>
      </div>
    </div>
    <div id="msgb_button_container">
      <div [ngClass]="buttonClassSelector" (click)="DefaultSetupConfigs.includes(buttons) ? ([MessageBoxButtons.Ok].includes(buttons) ? clickOk() : ([MessageBoxButtons.YesNo, MessageBoxButtons.YesNoCancel, MessageBoxButtons.YesNoClose].includes(buttons) ? clickYes() : clickCancel())) : clickButton1()">
        {{ DefaultSetupConfigs.includes(buttons) ? ([MessageBoxButtons.Ok].includes(buttons) ? l.get('general.ok') : ([MessageBoxButtons.YesNo, MessageBoxButtons.YesNoCancel, MessageBoxButtons.YesNoClose].includes(buttons) ? l.get('general.yes'): ([MessageBoxButtons.OkCancel].includes(buttons) ? l.get('general.cancel'): l.get('general.close')))) : button1Text }}
      </div>
      <div *ngIf="ConfigsWithSecondButton.includes(buttons)" [ngClass]="buttonClassSelector" (click)="DefaultSetupConfigs.includes(buttons) ? ([MessageBoxButtons.YesNo, MessageBoxButtons.YesNoCancel, MessageBoxButtons.YesNoClose].includes(buttons) ? clickNo() : clickOk()) : clickButton2()">
        {{ DefaultSetupConfigs.includes(buttons) ? ([MessageBoxButtons.YesNo, MessageBoxButtons.YesNoCancel, MessageBoxButtons.YesNoClose].includes(buttons) ? l.get('general.no') : l.get('general.ok')) : button2Text }}
      </div>
    </div>
    <div id="msgb_cancel" class="msgb_button_link" *ngIf="ConfigsWithCancelOrCloseButton.includes(buttons)"><div (click)="clickCancel()">{{[MessageBoxButtons.YesNoCancel, MessageBoxButtons.CustomButton1Cancel, MessageBoxButtons.CustomButton1Button2Cancel].includes(buttons) ? l.get('general.cancel') : l.get('general.close') }}</div></div>
    <div id="msgb_bottom_subtexts" class="msgb_subtext_container" *ngIf="subtextBottomLeft || subtextBottomRight">
      <div id="msgb_sub_bl" class="msgb_subtext" *ngIf="subtextBottomLeft && subtextBottomLeft !== ''">{{subtextBottomLeft}}</div>
      <div id="msgb_sub_br" class="msgb_subtext" *ngIf="subtextBottomRight && subtextBottomRight !== ''">{{subtextBottomRight}}</div>
    </div>
  </div>
</div>