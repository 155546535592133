<div class="sos_container" (click)="showAreas()">
    <div [inlineSVG]="'assets/images/info.svg'"></div>
    <span>SOS</span>
</div>

<app-messagebox *ngIf="timerActive" #timerPopup [buttons]="MessageBoxButtons.CustomButton1" [button1Text]="lang.get('general.cancel')" (button1Clicked)="cancelClicked()" [showCloseButton]="false" (messageboxClose)="cancelClicked()">
    <ng-container *ngIf="!isSending; else sending">
        <div class="timer_icon" [inlineSVG]="'assets/images/error.svg'"></div>
        <div class="timer_header">{{lang.get('systems.labels.sosTimerInfo')}}:</div>
        <div class="sos_timer">
            <circle-progress [percent]='currentProgress' [title]="secondsLeft + lang.get('configurators.labels.secondsShort')" [startFromZero]='false' ></circle-progress>
        </div>
    </ng-container>
</app-messagebox>

<app-messagebox *ngIf="areasVisible" #areaPopup [buttons]="MessageBoxButtons.CustomButton1" [button1Text]="lang.get('general.cancel')" (button1Clicked)="cancelClicked()" [showCloseButton]="false" (messageboxClose)="cancelClicked()">
    <ng-container *ngIf="!isSending; else sending">
        <div class="timer_header">{{lang.get('systems.labels.pickAreaForSos')}}:</div>
        <div class="separator8"></div>
        <app-button class="area_button" *ngFor="let area of areas" (click)="doSosForArea(area)">{{area.name}}</app-button>
    </ng-container>
</app-messagebox>

<ng-template #sending>
    <app-loader-round [isVisible]="true" [isInline]="true" [height]="150" [displayText]="lang.get('general.sending')"></app-loader-round>
</ng-template>
