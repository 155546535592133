import http from '../http';
import { BasicSuccessResponse, ErrorResponse, ExErrorResponse } from './common';

type DeleteZoneRequest = {
  system_id: number;
  zone_no: number;
};
type DeleteZoneResponse = ErrorResponse | BasicSuccessResponse;

export const deleteZone = (req: DeleteZoneRequest) => http.post<DeleteZoneResponse, DeleteZoneRequest>('/v3/api/delete/zone', req);

type AddZoneRequest = {
  system_id: number;
  zone: {
    queue_no: number;
    name: string;
    areas: number[];
  };
};
type AddZoneResponse = ErrorResponse | BasicSuccessResponse;

export const addZone = (req: AddZoneRequest) => http.post<AddZoneResponse, AddZoneRequest>('/v3/api/add/zone', req);

type EditZoneRequest = {
  system_id: number;
  zone: {
    queue_no: number;
    name: string;
    areas: number[];
  };
};
type EditZoneResponse = ErrorResponse | ExErrorResponse | BasicSuccessResponse;

export const editZone = (req: EditZoneRequest) => http.post<EditZoneResponse, EditZoneRequest>('/v3/api/edit/zone', req);

type MakeZoneVisibleRequest = {
  system_id: number;
  queue_no: number;
};
type MakeZoneVisibleResponse = ErrorResponse | BasicSuccessResponse;

export const makeZoneVisible = (req: MakeZoneVisibleRequest) => http.post<MakeZoneVisibleResponse, MakeZoneVisibleRequest>('/v3/api/show/zone', req);

type SetZoneNameRequest = {
  system_id: number;
  zone_number: number;
  zone_name: string;
};
type SetZoneNameResponse = ErrorResponse | BasicSuccessResponse;

export const setZoneName = (req: SetZoneNameRequest) => http.post<SetZoneNameResponse, SetZoneNameRequest>('/v3/api/change-zone-name', req);

type BypassZoneRequest = {
  system_id: number;
  area_id: number;
  zone: number;
  bypassed: boolean;
  pin?: string;
  remember_pin?: boolean;
};
type BypassZoneResponse = ErrorResponse | BasicSuccessResponse;

export const bypassZone = (req: BypassZoneRequest) => http.post<BypassZoneResponse, BypassZoneRequest>('/v3/api/bypass-single-zone', req);

type BypassZonesRequest = {
  system_id: number;
  area_id: number;
  zones: number[];
  pin?: string;
};
type BypassZonesResponse = ErrorResponse | BasicSuccessResponse;

export const bypassZones = (req: BypassZonesRequest) => http.post<BypassZonesResponse, BypassZonesRequest>('/v3/api/bypass-multi-zones', req);
