import http from '../http';
import { BasicSuccessResponse } from './common';

type EditRegionRequest = {
  id?: number;
  name?: string;
  api_host?: string;
  api_path?: string;
  region_version?: number;
};
type EditRegionResponse = BasicSuccessResponse & { id: number };

export const editRegion = (req: EditRegionRequest) => http.post<EditRegionResponse, EditRegionRequest>('/v3/api/region', req);

type DeleteRegionRequest = { id: number };
type DeleteRegionResponse = BasicSuccessResponse;

export const deleteRegion = (req: DeleteRegionRequest) => http.delete<DeleteRegionResponse, DeleteRegionRequest>('/v3/api/region', req);

export type RegionSlim = { id: number; name: string; api_host: string };
type RegionSlimResponse = BasicSuccessResponse & { regions: RegionSlim[] };
export const getRegions = () => http.get<RegionSlimResponse>('/v3/api/regions', undefined);
