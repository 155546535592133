import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PgmType } from 'src/app/models/pgm';
import http from '../http';
import { BasicSuccessResponse, ErrorResponse, ExErrorResponse, objectToParams, SystemZoneData } from './common';

export enum AreaStatus {
  Arm = 1,
  Stay = 3,
  Sleep = 2,
  Off = 0,
}

export enum AreaState {
  Unknown = 0,
  Disarmed = 1,
  Armed = 2,
  Stay = 3,
  Sleep = 4,
  ArmingInProgress = 5,
  DisarmingInProgress = 6,
  DisarmedReady = 7,
  DisarmedNotReady = 8,
  PanicButtons = 18,
  Group = 19,
}

export const areaStateValues: AreaState[] = Object.keys(AreaState).filter(k => !isNaN(Number(k))).map(n => Number(n));

export enum AlarmType {
  General = 0,
  Fire = 1,
}

export type SystemAreaData = {
  id: number;
  system_id: number;
  name: string;
  queue_no: number;
  monas_id: null;
  status: AreaState;
  alarm_in_memory: boolean;
  status_time: number;
  last_person_name: string;
  alarm_time: number;
  alarm_type: AlarmType;
  show_keypad: boolean;
  can_bypass: boolean;
  zones: SystemZoneData[];
};

type DeleteAreaRequest = { system_id: number; area_id: number };
type DeleteAreaResponse = ErrorResponse | { success: true; areaId: number; areaCount: number };
const deleteArea = (req: DeleteAreaRequest) => http.delete<DeleteAreaResponse, DeleteAreaRequest>('/v3/api/area', req);

type AddAreaRequest = {
  system_id: number;
  area: { name: string; queue_no: number };
  pgm?: { id: number; pulseTime: number; type: PgmType };
};
type AddAreaResponse = ErrorResponse | { success: true; area_id: number };
const addArea = (req: AddAreaRequest) => http.post<AddAreaResponse, AddAreaRequest>('/v3/api/area', req);

type EditAreaRequest = {
  system_id: number;
  area: { name: string; queue_no: number };
  pgm?: { id: number; pulseTime: number; type: PgmType; control_area: boolean };
};
type EditAreaResponse = ErrorResponse | ExErrorResponse | BasicSuccessResponse;
const editArea = (req: EditAreaRequest) => http.put<EditAreaResponse, EditAreaRequest>('/v3/api/area', req);

type SetAreaStatusRequest = {
  area_id: number;
  new_state: AreaState;
  old_state: AreaState;
  pin?: string;
  remember_pin?: boolean;
};
export type SetAreaStatusResponse =
  (ErrorResponse & { isLoggedIn: boolean; badPin: boolean; failedZones?: object })
  | {
      success: boolean;
      state: AreaState;
      failedZones?: object;
      error: string;
      isLoggedIn: true;
      hasFailedzones: boolean;
      badPin: boolean;
    };
const setAreaStatus = (req: SetAreaStatusRequest) => http.post<SetAreaStatusResponse, SetAreaStatusRequest>('/v3/api/set-area-status', req);

type MuteAreaAlarmsRequest = {
  area_ids: number[];
};
type MuteAreaAlarmsResponse =
  ErrorResponse | { success: boolean; error?: string };
const muteAlarmInAreas = (req: MuteAreaAlarmsRequest) => http.post<MuteAreaAlarmsResponse, MuteAreaAlarmsRequest>('/v3/api/mute-area-alarms', req);

type GetAreaZonesRequest =
  | {
      system_id: number;
      area_number: number;
    }
  | {
      system_id: 0;
      area_number: number;
      imei: string;
      mpass?: string;
      srv?: number;
    };
type GetAreaZonesResponse =
  | ErrorResponse
  | (BasicSuccessResponse & {
      zones: {
        enabled: boolean;
        alarm: boolean;
        failure: boolean;
        bypass: boolean;
      }[];
    });

export const getAreaZones = (req: GetAreaZonesRequest) => http.get<GetAreaZonesResponse, URLSearchParams>('/v3/api/area-zones', objectToParams(req));

type SetAreaNameRequest = {
  system_id: number;
  area_id: number;
  area_name: string;
};
type SetAreaNameResponse = ErrorResponse | BasicSuccessResponse;

export const setAreaName = (req: SetAreaNameRequest) => http.post<SetAreaNameResponse, SetAreaNameRequest>('/v3/api/change-area-name', req);

type GetAreasResponseInternal =
  | { success: false; isLoggedIn: false }
  | (BasicSuccessResponse & {
      areas: string;
    });
type GetAreasResponse =
  | { success: false; isLoggedIn: false }
  | (BasicSuccessResponse & {
      areas: {
        area_id: number;
        alarmed: boolean;
        status: AreaStatus;
        name: string;
        system_name: string;
        is_bound_to_pgm: 0 | 1;
        pgm_enabled: 0 | 1;
        no_stay_sleep_buttons: 0 | 1;
        use_away: boolean;
      }[];
    });

/** Widget API */
export const getAreas = (): Observable<GetAreasResponse> =>
  http.get<GetAreasResponseInternal>('/v3/api/get-area-list', undefined).pipe(map((res) => (res.success ? { ...res, areas: JSON.parse(res.areas) } : res)));

type GetAreaStatusRequest = {
  area_id: number;
};

type GetAreaStatusResponse =
  | (ErrorResponse & { isLoggedIn: boolean })
  | (BasicSuccessResponse & {
      status: AreaStatus;
      name: string;
      area_id: number;
      system_id: number;
      ask_for_pin: boolean;
      no_stay_sleep_buttons: boolean;
      alarmed: boolean;
      can_react: boolean;
      use_password: boolean;
      is_bound_to_pgm: 0 | 1;
      pgm_enabled: 0 | 1;
      isLoggedIn: true;
      use_away: boolean;
    });

/** Widget API */
export const getAreaStatus = ({ area_id }: GetAreaStatusRequest) =>
  http.get<GetAreaStatusResponse, URLSearchParams>('/v3/api/get-area-status', new URLSearchParams({ area_id: area_id.toString() }));

type SetAreaStatus2Request = {
  area_id: number;
  current_state: AreaStatus;
};
type SetAreaStatus2Response =
  | (ErrorResponse & { isLoggedIn?: boolean; badPin?: boolean })
  | (BasicSuccessResponse & {
      success: boolean;
      state: AreaStatus;
      failedZones: string[];
      error: string;
      isLoggedIn: true;
      hasFailedzones: boolean;
      badPin: boolean;
    });

/** Widget API */
export const setAreaStatus2 = (req: SetAreaStatus2Request) => http.get<SetAreaStatus2Response, URLSearchParams>('/v3/api/set-area-status', objectToParams(req));
type SilenceSirenRequest = {
  area_id: number;
  pin?: string;
  remember_pin?: boolean;
};
type SilenceSirenResponse = ErrorResponse | BasicSuccessResponse;

export const silenceSiren = (req: SilenceSirenRequest) => http.post<SilenceSirenResponse, SilenceSirenRequest>('/v3/api/silence-siren', req);

type CancelAlarmRequest = SilenceSirenRequest;
type CancelAlarmResponse = SilenceSirenResponse;

export const cancelAlarm = (req: CancelAlarmRequest) => http.post<CancelAlarmResponse, CancelAlarmRequest>('/v3/api/cancel-alarm', req);

export default {
  add: addArea,
  save: editArea,
  delete: deleteArea,
  changeStatus: setAreaStatus,
  muteAlarms: muteAlarmInAreas,
};
