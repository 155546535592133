import { Location } from '@angular/common';
import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BackButtonService {
  constructor(private l: Location, zone: NgZone) {
    window.backJsi = {
      doBack: () => {
        zone.run(() => {
          this.performBack();
        });
      },
    };
  }

  public performBack() {
    this.l.back();
  }
}
