import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { ErrorResponse } from 'src/api/v3/common';
import requests from 'src/api/v3/requests';
import { SosType } from 'src/api/v3/system';
import { UserCoordinateType } from 'src/api/v3/user';
import { PlatformService } from 'src/app/api/platform.service';
import { SystemService } from 'src/app/api/system/system.service';
import { UserService } from 'src/app/api/user.service';
import { LoaderRoundComponent } from 'src/app/components/loader-round/loader-round.component';
import { TAreaData } from 'src/app/models/area-data';
import { ToastType } from 'src/app/models/toaster-type';
import { MessageBoxButtons } from 'src/app/popups/messagebox/messagebox-config';
import { MessageboxComponent } from 'src/app/popups/messagebox/messagebox.component';
import { LanguageService } from 'src/app/services/language.service';
import { SystemsService } from 'src/app/services/systems.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { ButtonComponent } from 'src/app/ui/button/button.component';

@Component({
  selector: 'app-sos-button',
  templateUrl: './sos-button.component.html',
  styleUrls: ['./sos-button.component.scss'],
  standalone: true,
  imports: [CommonModule, InlineSVGModule, MessageboxComponent, NgCircleProgressModule, ButtonComponent, LoaderRoundComponent],
})
export class SosButtonComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('areaPopup') areaPopup: MessageboxComponent;
  @Input() systemId: number;
  private readonly TIMER_LENGTH = 4; // sekundės

  public readonly MessageBoxButtons = MessageBoxButtons;

  private timer = null;
  private singleArea: TAreaData;

  public isSending = false;
  public timerActive = false;
  public secondsLeft = 0;
  public currentProgress = 100;
  public areas: TAreaData[] = [];
  public areasVisible = false;

  constructor(
    public lang: LanguageService,
    private us: UserService,
    private systems: SystemsService,
    private toasterService: ToasterService,
    private systemService: SystemService,
    private platform: PlatformService,
  ) { }

  ngOnInit(): void {
  }
  ngOnDestroy() {
    this.cancelTimer();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.systemId && this.areasVisible ) {
      const system = this.systems.getSystem(this.systemId) || this.systemService.systems.get(this.systemId);
      if ( !system ) { return; }
      const visibleAreas = system.areas.filter((a) => this.systems.systemHelper(system).can.see.area(a.queue_no));
      this.areas = visibleAreas;
    }
  }

  public showAreas() {
    const system = this.systems.getSystem(this.systemId) || this.systemService.systems.get(this.systemId);
    if ( !system ) { return; }
    const visibleAreas = system.areas.filter((a) => this.systems.systemHelper(system).can.see.area(a.queue_no));
    if ( visibleAreas.length === 0 ) { return; }
    if ( system.sos_type === SosType.WithGPS ) {
      if ( this.platform.isAndroid() ) {
        try {
          this.platform.androidHandler().sendCoordinates(UserCoordinateType.SOS, system.id);
        } catch (e) { }
      } else if ( this.platform.isApple() ) {
        this.platform.appleHandler().sendCoordinates.postMessage({ type: UserCoordinateType.SOS, systemId: system.id });
      }
    }
    if ( visibleAreas.length === 1 ) {
      this.singleArea = visibleAreas[0];
      this.startTimer();
      return;
    }
    this.areasVisible = true;
    this.areas = visibleAreas;
  }

  public cancelClicked() {
    this.areasVisible = false;
    this.cancelTimer();
  }

  private cancelTimer() {
    clearInterval(this.timer);
    this.timerActive = false;
  }

  private startTimer() {
    this.secondsLeft = this.TIMER_LENGTH;
    this.timerActive = true;
    this.timer = setInterval(async () => {
      this.secondsLeft--;
      this.currentProgress = Math.floor(this.secondsLeft / this.TIMER_LENGTH * 100);
      if ( this.secondsLeft <= 0) {
        clearInterval(this.timer);
        await this.doSosForArea(this.singleArea);
        this.timerActive = false;
      }
    }, 1000);
  }

  public async doSosForArea(area: TAreaData) {
    const system = this.systems.getSystem(this.systemId) || this.systemService.systems.get(this.systemId);
    if ( !system ) { return; }
    if ( !this.us.currentUser ) { return; }
    if ( !area ) { return; }
    this.isSending = true;
    try {
      const res = await requests.system.sendSos({system_id: this.systemId, area_id: area.id}).toPromise();
      if ( !res.success ) {
        this.toasterService.postError((res as ErrorResponse).error);
      } else {
        this.areaPopup.clickCancel();
        this.toasterService.post(this.lang.get('systems.labels.sosSentOk'), ToastType.Success, true, 5000);
      }
    } catch (e) { }
    this.isSending = false;
  }
}
